import { action } from 'easy-peasy';
import initStore from '../initStore';

import { IActionResetStoreReport } from '../types';

const resetReportsStore: IActionResetStoreReport = action(state => {
  state.dashboard = initStore.dashboard;
  state.recertification = initStore.recertification;
  state.clientAllocationVisits = initStore.clientAllocationVisits;
  state.demographics = { ...initStore.demographics, filters: { teamIds: [] } };
  state.visitsByClient = initStore.visitsByClient;
  state.visitsByTeam = initStore.visitsByTeam;
  state.visitStatus = initStore.visitStatus;
  state.treatmentPlans = { ...initStore.treatmentPlans, filters: { teamIds: [] } };
  state.toxicology = { ...initStore.toxicology, filters: { teamIds: [] } };
  state.clientTreatmentPlans = {
    ...initStore.clientTreatmentPlans,
    filters: { teamId: undefined },
  };
  state.teamMemberDashboard = { ...initStore.teamMemberDashboard, filters: { teams: [] } };
  state.locationGroup = { ...initStore.locationGroup, filters: { teamIds: [] } };
});

export default resetReportsStore;
