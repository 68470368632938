import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamMemberActions, IThunkActivateGlobalUser } from '../types';

const onActivateGlobalUser: IThunkActivateGlobalUser = thunk(
  async ({ activateTeamMember }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, teamMemberId } = payload;

    await api.actTeamMember.activateGlobalUser(clinicId, teamMemberId);

    activateTeamMember(teamMemberId);
  },
);

export default onActivateGlobalUser;
