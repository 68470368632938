import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import { MouseEvent, ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';

import styles from './Tooltip.module.scss';

interface IProps {
  body: ReactNode;
  icon: ReactNode;
  className?: string;
}

const Tooltip = ({ body, icon, className }: IProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'popover' : undefined;

  return (
    <>
      <IconButton
        aria-describedby={id}
        onMouseEnter={handleClick}
        onMouseLeave={handleClose}
        size="small"
        className={styles.iconButton}
      >
        {icon}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classNames(styles.tooltip, className) }}
        style={{ pointerEvents: 'none' }}
      >
        {body}
      </Popover>
    </>
  );
};

export default Tooltip;
