import { action } from 'easy-peasy';
import { IActionAiSettings } from '../types';

const setAiSystemSettings: IActionAiSettings = action((state, aiSettings) => {
  if (aiSettings) {
    state.currentSystem = aiSettings;
  }
});

export default setAiSystemSettings;
