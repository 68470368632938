import AddIcon from '@material-ui/icons/Add';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';

import Button from '~/ui/components/common/Button';
import ClientAllocation from '../../LocalAdmin/ClientAllocation';

import { ADD_MY_CLIENT_ALLOCATION } from '~/ui/constants/paths';
import styles from './MyClientAllocations.module.scss';

const MyClientAllocations = (): ReactElement => {
  const navigate = useNavigate();

  const { clinic: userClinic } = useStoreState(state => state.user.current);

  return (
    <div>
      <div className={styles.header}>
        <h2>Client Allocation</h2>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate(ADD_MY_CLIENT_ALLOCATION)}
        >
          Add Client Allocation
        </Button>
      </div>
      <ClientAllocation clinicId={String(userClinic.id)} />
    </div>
  );
};

export default MyClientAllocations;
