import unionBy from 'lodash.unionby';
import { IActTeamMemberAvailableList } from '~/services/api/actTeamMember/types';
import { IAddClientAllocation, IUpdateClientAllocation } from '~/services/api/clientAllocation';
import {
  IAllocationClientsByGroup,
  IClientAllocationDetailed,
  IRelatedClientsForAllocation,
} from '~/services/api/clientAllocation/types';
import { IRole } from '~/store/user/types';
import { IOption } from '~/types';

export interface ITeamMemberMapped {
  id: number;
  fullName: string;
  photo: string | null;
  isDeleted: boolean;
  roles?: IRole[];
}

interface IGroupedClientAllocationData {
  allocationsForEdit: IUpdateClientAllocation[];
  allocationsForAdd: IAddClientAllocation[];
  allocationsForDelete: number[];
}

export const formValuesMapper = (obj: { [key: string]: number[] }): number[] =>
  Object.keys(obj).reduce((acc, val) => [...acc, ...(obj[val] || [])], []);

export const groupClientAllocationData = (
  values: {
    [key: string]: number[];
  },
  allAdd?: boolean,
): IGroupedClientAllocationData =>
  Object.keys(values)
    .filter(key => !key.includes('groups'))
    .reduce(
      (acc: IGroupedClientAllocationData, key: string) => {
        const keyArray = key.split('_');
        const userId = keyArray[1];

        const locationGroupIds = Array.from(
          new Set([...(values[`groups_${userId}${keyArray[2] ? `_${keyArray[2]}` : ''}`] || [])]),
        ); // remove duplicates

        if (typeof values[key]?.length === 'number' && !values[key]?.length) {
          acc.allocationsForDelete.push(Number(keyArray[2]));
        } else if (keyArray[2] && !allAdd) {
          acc.allocationsForEdit.push({
            id: keyArray[2],
            clientIds: values[key]?.map(id => id),
            locationGroupIds,
          });
        } else if (values[key]?.length) {
          acc.allocationsForAdd.push({
            userId,
            clientIds: values[key]?.map(id => id),
            locationGroupIds,
          });
        }

        return acc;
      },
      { allocationsForEdit: [], allocationsForAdd: [], allocationsForDelete: [] },
    );

export function mergeAllocationByUserId(
  clientAllocations: IClientAllocationDetailed[],
): IClientAllocationDetailed[] {
  const mergedArray: IClientAllocationDetailed[] = [];

  clientAllocations.forEach(item => {
    // Find an existing entry with the same user ID
    const existing = mergedArray.find(i => i.user.id === item.user.id);

    if (existing) {
      // Merge clients and locationGroups
      existing.user = {
        ...existing.user,
        clients: unionBy(
          [...(existing.user.clients || []), ...(item.user.clients || [])].map(client => ({
            ...client,
          })),
          'id',
        ),
      };

      // Merge locationGroups, ensuring immutability
      existing.locationGroups = unionBy(
        [...(existing.locationGroups || []), ...(item.locationGroups || [])].map(group => ({
          ...group,
        })),
        'id',
      );
    } else {
      // Add a deep copy of the item to avoid reference issues
      mergedArray.push({
        ...item,
        user: {
          ...item.user,
          clients: item.user.clients.map(client => ({ ...client })),
        },
        locationGroups: item.locationGroups.map(group => ({ ...group })),
      });
    }
  });

  return mergedArray;
}

export const prepareDefaultValues = (
  clientAllocations: IClientAllocationDetailed[],
): { [key: string]: number[] } => {
  const usr = mergeAllocationByUserId(clientAllocations).reduce((acc, currentValue) => {
    if (currentValue.isArchived || !currentValue.user?.clients?.length) {
      return acc;
    }
    return {
      ...acc,
      [`clients_${currentValue.user.id}_${currentValue.id}`]: currentValue.user.clients.map(
        item => item.id,
      ),
      ...(currentValue.locationGroups.length
        ? {
            [`groups_${currentValue.user.id}_${currentValue.id}`]:
              currentValue.locationGroups?.map(item => item.id) || [],
          }
        : {}),
    };
  }, {});
  return usr;
};

export const teamMemberMapper = (teamMember: IActTeamMemberAvailableList): ITeamMemberMapped => ({
  id: teamMember.id,
  fullName: `${teamMember.firstName} ${teamMember.lastName}`,
  photo: teamMember.photo,
  isDeleted: teamMember.isDeleted || !teamMember.isActive,
  roles: teamMember.roles,
});

export const clientMapper = (client: IRelatedClientsForAllocation): IOption => ({
  value: client.id,
  label: `${client.firstName} ${client.lastName}`,
});

export const formatLocationGroupOptions = (groups: IAllocationClientsByGroup[]): IOption[] =>
  (groups || []).map(group => ({
    value: group.locationGroup.id,
    label: group.locationGroup.name,
  }));

export const sortByName = (
  a: IActTeamMemberAvailableList,
  b: IActTeamMemberAvailableList,
): number => (a.lastName.toLocaleLowerCase() < b.lastName.toLocaleLowerCase() ? -1 : 1);

// need to extract all assigned clients ids
export const clientAllocationMapper = (clientAllocations: IClientAllocationDetailed[]): number[] =>
  clientAllocations
    .map(item => item.user.clients)
    .flat()
    .map(item => item.id);
