import { action } from 'easy-peasy';
import { IActionArchiveLocalAdmin } from '../types';

const archiveLocalAdmin: IActionArchiveLocalAdmin = action((state, localAdminId) => {
  const updatedList = state.list.filter(item => item.id !== localAdminId);

  state.list = updatedList;
});

export default archiveLocalAdmin;
