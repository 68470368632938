import { ReactElement, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import TestAiRequestForm from '../TestAiRequestForm';

import { IAiSettings } from '~/services/api/ai/types';
import { IAiTestRequestFormValues } from '../../types';

const TestAiRequest = ({ aiSettings }: { aiSettings: IAiSettings }): ReactElement => {
  const [sendingTestAiRequest, setSendingTestAiRequest] = useState(false);

  const { onSendAiRequest, setAiTestResponse } = useStoreActions(actions => actions.ai);
  const { showError } = useStoreActions(action => action.snackbar);
  const { testAiResponse } = useStoreState(store => store.ai);

  const handleSendAiTestRequest = async (values: IAiTestRequestFormValues) => {
    try {
      setSendingTestAiRequest(true);
      await onSendAiRequest({ ...values, ...aiSettings });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setSendingTestAiRequest(false);
    }
  };

  useEffect(
    () => () => {
      setAiTestResponse(null);
    },
    [setAiTestResponse],
  );

  return (
    <TestAiRequestForm
      testAiResponse={testAiResponse}
      title="Send Test AI Request"
      onSubmit={handleSendAiTestRequest}
      loading={sendingTestAiRequest}
    />
  );
};

export default TestAiRequest;
