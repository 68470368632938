import Grid from '@material-ui/core/Grid';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import useFeature from '~/store/clinic/hooks/useFeature';
import { extractErrorMessage } from '~/utils/error/error';
import formatActTeamMemberOptions from '~/utils/formatActTeamMemberOptions';
import formatClientAddress from '~/utils/dataFormat/formatClientAddress';
import formatDictionaryOptions from '~/utils/formatDictionaryOptions';
import formatEnumToOptions from '../../utils/formatEnumToOptions';
import getSeconds from '../../utils/getSeconds';
import formatObjectivesOptions from '~/utils/formatObjectivesOptions';
import formatToxicologyPayload from '../../utils/formatToxicologyPayload';
import isStatusDone from '../../utils/isStatusDone';
import getSelectedObjectives from '../../utils/getSelectedObjectives';

import Button from '~/ui/components/common/Button';
import Loader from '~/ui/components/common/Loader';
import Checkbox from '~/ui/components/inputs/Checkbox';
import DatePicker from '~/ui/components/inputs/DatePicker';
import Input from '~/ui/components/inputs/Input';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import TimePicker from '~/ui/components/inputs/TimePicker';
import InputMask from '~/ui/components/inputs/InputMask';
import Testing from '../Testing';
import ObjectivesCheckboxGroup from '../ObjectivesCheckboxGroup';

import validation from './validation';
import { IOption } from '~/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IVisitForm, IVisitRequestPayload } from '~/services/api/visits/types';
import { IVisitTreatmentPlan, PaperWorkStatus } from '~/services/api/clientDetails/types';
import { PREVIEW_VISIT, VISITS } from '~/ui/constants/paths';
import { TypeOfVisit, visitTypes } from '../../constants/filterOptions';
import addressAdditionalOption from '../../constants/addressAdditionalOption';

import { ReactComponent as ClockIcon } from '~/ui/assets/images/clockIcon.svg';
import styles from './Form.module.scss';

interface IProps {
  visitId: string;
  defaultValues: IVisitForm | null;
  fromPreview?: boolean;
  clientNoShow?: boolean;
  treatmentPlan?: IVisitTreatmentPlan;
  paperworkStatus?: PaperWorkStatus;
}
const Form = ({
  visitId,
  defaultValues,
  fromPreview,
  paperworkStatus,
  treatmentPlan,
  clientNoShow: initialClientNoShow,
}: IProps): ReactElement => {
  const initialObjectives = getSelectedObjectives(treatmentPlan);

  const [loading, setLoading] = useState<boolean>(true);
  const [noShowReasons, setNoShowReasons] = useState<IOption[]>([]);
  const [interventions, setInterventions] = useState<IOption[]>([]);
  const [teamMembers, setTeamMembers] = useState<IOption[]>([]);
  const [objectives, setObjectives] = useState<IOption[]>(initialObjectives);
  const [states, setStates] = useState<IOption[]>([]);
  const navigate = useNavigate();

  const query = useQuery();
  const teamId = query.get('teamId');
  const clientId = query.get('clientId');

  const {
    control,
    register,
    unregister,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: validation,
    defaultValues: {
      ...defaultValues,
      billable: defaultValues ? defaultValues.billable : true,
      sendToEmr: defaultValues ? defaultValues.sendToEmr : true,
      clientNoShow: defaultValues ? defaultValues.clientNoShow : initialClientNoShow,
      address: 0,
      duration: defaultValues?.duration || '',
    },
  });
  const { type, clientNoShow, address, billable, toxicology, bloodDrawn } = watch();

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { addresses } = useStoreState(state => state.clientDetails);
  const { current: visit } = useStoreState(state => state.visits);

  const { onGetClient } = useStoreActions(action => action.client);
  const { onAddVisit, onEditVisit } = useStoreActions(action => action.visits);
  const { onGetAddresses } = useStoreActions(actions => actions.clientDetails);
  const { showError } = useStoreActions(action => action.snackbar);

  const { isTreatmentPlansAvailable, isBidirectionalAvailable } = useFeature();

  const visitedPersonsList = useMemo((): IOption[] => {
    if (client) {
      const array = [
        { label: `${client.firstName} ${client.lastName} | Client`, value: client.id },
        { label: 'Hospital Staff', value: 0 },
      ];
      client.supportContacts.forEach(contact => {
        if (!contact.isArchived) {
          array.push({
            label: `${contact.firstName} ${contact.lastName} | ${contact.relationship.name}`,
            value: contact.id,
          });
        }
      });
      return array;
    }
    return [];
  }, [client]);

  const onCancel = (): void => {
    if (fromPreview) {
      navigate(
        PREVIEW_VISIT.replace(':visitId', String(visitId)).concat(
          `?teamId=${teamId}&clientId=${clientId}`,
        ),
      );
    } else {
      navigate(VISITS.concat(`?teamId=${teamId}&clientId=${clientId}`));
    }
  };

  const onSubmit = async (values: IVisitForm) => {
    const {
      type: typeOfVisit,
      visitedPeople,
      date,
      startTime,
      line1,
      city,
      stateId,
      line2,
      zipCode,
      duration,
      noShowReason,
      bloodDrawn: bd,
      toxicology: tx,
      billable: isBillable,
      sendToEmr,
      address: addressId,
      ...rest
    } = values;

    // if address entered manually
    let visitAddress =
      line1 && stateId ? { line1, line2: defaultValues?.line2, stateId, city, zipCode } : null;

    // if address selected from the list
    if (addressId) {
      const selectedAddress = addresses.find(item => item.id === addressId);
      if (selectedAddress) {
        visitAddress = {
          line1: selectedAddress.line1,
          line2: selectedAddress.line2,
          stateId: selectedAddress.state.id,
          city: selectedAddress.city,
          zipCode: selectedAddress.zipCode,
        };
      }
    }

    const requestPayload: IVisitRequestPayload = {
      type: Object.keys(visitTypes)[Number(typeOfVisit)],
      durationSeconds: !clientNoShow ? getSeconds(duration) : null,
      date,
      startTime: startTime.toString().split(' ')[4],
      clientId: Number(clientId),
      isClientVisited: !clientNoShow ? visitedPeople.includes(client.id) : false,
      isHospitalStaffVisited: !clientNoShow ? visitedPeople.includes(0) : false,
      visitedPeople: !clientNoShow
        ? visitedPeople.filter(person => person !== client.id && person)
        : null,
      address: visitAddress,
      noShowReason: clientNoShow ? noShowReason : null,
      paperworkStatus: paperworkStatus || rest.paperworkStatus,
      treatmentPlanDetails:
        treatmentPlan?.goals.length && isTreatmentPlansAvailable
          ? {
              id: treatmentPlan.id,
              reviewedObjectives: objectives.map(item => Number(item.value)),
            }
          : null,
      toxicologyResults: clientNoShow
        ? null
        : formatToxicologyPayload({ toxicology: tx, bloodDrawn: bd }),
      billable: clientNoShow ? false : !!isBillable,
      sendToEmr: isBidirectionalAvailable ? sendToEmr : false,
      ...rest,
    };

    try {
      if (!defaultValues) {
        await onAddVisit({
          requestInfo: { clinicId: String(current?.clinic?.id), teamId },
          requestPayload,
        });
      } else {
        await onEditVisit({
          requestInfo: { clinicId: String(current?.clinic?.id), teamId, visitId },
          requestPayload,
        });
      }
      onCancel();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const hasDefaultValues = useMemo(() => !!defaultValues, [defaultValues]);

  const onMount = useCallback(async () => {
    if (current?.clinic?.id && teamId && clientId) {
      try {
        setLoading(true);
        await onGetClient({
          clinicId: String(current?.clinic?.id),
          teamId,
          clientId,
        });

        await onGetAddresses({ clinicId: String(current?.clinic?.id), teamId, clientId });

        const { data: members } = await api.actTeamMember.getActTeamMemberList(
          String(current?.clinic?.id),
          teamId,
        );
        setTeamMembers(formatActTeamMemberOptions(members, !hasDefaultValues));

        const { data: reasons } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.VisitNoShowReason,
        );
        const { data: interventionsDictionary } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.Intervention,
        );
        setInterventions(formatDictionaryOptions(interventionsDictionary));
        setNoShowReasons(formatDictionaryOptions(reasons));
        const { data: dictionaryStates } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.State,
        );
        setStates(formatDictionaryOptions(dictionaryStates));
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
  }, [
    clientId,
    current?.clinic?.id,
    hasDefaultValues,
    onGetAddresses,
    onGetClient,
    showError,
    teamId,
  ]);

  useEffect(() => {
    onMount();
  }, [visitId, onMount]);

  const isPersonVisit = type === 0 || type === 1;

  useEffect(() => {
    if (!clientNoShow) {
      setValue('noShowReason', null);
    } else {
      unregister(['startTime']);
    }
    if (!isPersonVisit) {
      unregister(['stateId', 'zipCode', 'line1', 'city']);
    }
  }, [clientNoShow, isPersonVisit, setValue, unregister]);

  useEffect(() => {
    if (clientNoShow) {
      // reset toxicologyResults if client no show
      unregister([
        'toxicology.status',
        'toxicology.result',
        'toxicology.date',
        'bloodDrawn.status',
        'bloodDrawn.drawDate',
      ]);
    }
  }, [clientNoShow, unregister]);

  // reset testing data if status not Done
  useEffect(() => {
    if (!isStatusDone(toxicology?.status)) {
      setValue('toxicology.result', null);
      setValue('toxicology.date', null);
      clearErrors(['toxicology.result', 'toxicology.date']);
    } else if (!isStatusDone(bloodDrawn?.status)) {
      setValue('bloodDrawn.drawDate', null);
      clearErrors(['bloodDrawn.drawDate']);
    }
  }, [toxicology?.status, bloodDrawn?.status, setValue, clearErrors]);

  const filteredMembers = useMemo(
    () =>
      teamMembers.filter(
        member => !member.isDisabled || defaultValues?.visitors.includes(member.value as number),
      ),
    [teamMembers, defaultValues],
  );

  const filteredAddresses = useMemo(() => {
    const newAddresses = addresses.reduce((acc, obj) => {
      if (!obj.isArchived) {
        acc.push({ value: obj.id, label: formatClientAddress(obj) });
      }

      return acc;
    }, []);

    newAddresses.unshift(addressAdditionalOption);

    return newAddresses;
  }, [addresses]);

  if (loading) return <Loader />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className={styles.form}>
        <Grid item sm={12}>
          <h4>Main information</h4>
        </Grid>
        <Grid item sm={3}>
          <DatePicker
            control={control}
            errors={errors}
            name="date"
            openTo="date"
            label="Date of Visit"
            maxDate={new Date().toISOString()}
          />
        </Grid>
        <Grid item sm={3}>
          <Select
            control={control}
            errors={errors}
            name="type"
            options={formatEnumToOptions(TypeOfVisit)}
            label="Type of Visit"
            hideSelectedOptions={false}
            openMenuOnFocus
          />
        </Grid>
        <Grid item sm={3}>
          <TimePicker
            control={control}
            errors={errors}
            name="startTime"
            label={clientNoShow ? 'Time of Visit' : 'Start time'}
          />
        </Grid>
        {!clientNoShow && (
          <Grid item sm={3}>
            <InputMask
              alwaysShowMask={false}
              mask="99:99"
              control={control}
              errors={errors}
              name="duration"
              label="Duration"
              endAdornment={<ClockIcon />}
              maskPlaceholder="-"
            />
          </Grid>
        )}
        {!clientNoShow && (
          <>
            <Grid item sm={9}>
              <Select
                control={control}
                errors={errors}
                name="visitedPeople"
                options={visitedPersonsList}
                label="Visited Person"
                isMulti
                hideSelectedOptions={false}
                openMenuOnFocus
              />
            </Grid>
            <Grid item sm={1} />
          </>
        )}
        <Grid item sm={9}>
          <Select
            control={control}
            errors={errors}
            name="visitors"
            isMulti
            label="Visited By"
            options={filteredMembers}
            hideSelectedOptions={false}
            openMenuOnFocus
          />
        </Grid>
        {!clientNoShow && (
          <Grid container item sm={9}>
            <Testing
              control={control}
              errors={errors}
              toxicology={toxicology}
              bloodDrawn={bloodDrawn}
            />
          </Grid>
        )}
        <Grid item sm={9}>
          <Input
            register={register}
            errors={errors}
            name="note"
            label="Add Note"
            multiline
            minRows={4}
          />
        </Grid>
        {isBidirectionalAvailable && (
          <Grid item sm={3}>
            <Checkbox
              control={control}
              errors={errors}
              name="sendToEmr"
              label="Send to EHR"
              disabled={defaultValues?.sendToEmr}
            />
          </Grid>
        )}
        {isPersonVisit && (
          <Grid item sm={9}>
            <Select
              hideSelectedOptions={false}
              name="address"
              options={filteredAddresses}
              control={control}
              errors={errors}
              label="Select Address"
            />
          </Grid>
        )}
        {isPersonVisit && !address && (
          <>
            <Grid item sm={12}>
              <h4>Address</h4>
            </Grid>
            <Grid item sm={3}>
              <Input type="text" register={register} errors={errors} name="city" label="City" />
            </Grid>
            <Grid item sm={3}>
              <Select
                control={control}
                errors={errors}
                name="stateId"
                label="State"
                options={states}
                hideSelectedOptions={false}
                openMenuOnFocus
              />
            </Grid>
            <Grid item sm={3}>
              <InputMask
                control={control}
                errors={errors}
                name="zipCode"
                label="Zip Code"
                alwaysShowMask={false}
                mask="99999"
              />
            </Grid>
            <Grid item sm={9}>
              <Input type="text" register={register} errors={errors} name="line1" label="Address" />
            </Grid>
          </>
        )}
        <Grid container item sm={9}>
          <Grid item sm={12}>
            <Checkbox
              control={control}
              errors={errors}
              name="clientNoShow"
              label="Client No Show"
              disabled={!defaultValues}
            />
          </Grid>
          {clientNoShow && (
            <Grid item sm={12}>
              <Select
                control={control}
                errors={errors}
                name="noShowReason"
                label="No Show Reasons"
                options={noShowReasons}
                hideSelectedOptions={false}
                openMenuOnFocus
              />
            </Grid>
          )}
        </Grid>
        {!!treatmentPlan?.goals?.length && !clientNoShow && isTreatmentPlansAvailable && (
          <>
            <Grid container item sm={9} spacing={2}>
              <Grid item sm={12}>
                <h4>Objectives Reviewed</h4>
              </Grid>
              {treatmentPlan?.goals.map(goal => (
                <Grid item sm={12} key={`${goal.text}-${goal.id}`}>
                  <ObjectivesCheckboxGroup
                    title={goal.text}
                    options={formatObjectivesOptions(goal.objectives)}
                    selected={objectives}
                    setSelected={setObjectives}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
        {!clientNoShow && (
          <Grid container item sm={3} spacing={2}>
            <Grid item sm={12}>
              <Checkbox control={control} errors={errors} name="billable" label="Billable Visit" />
            </Grid>
            {billable && (
              <Grid item sm={12}>
                <Select
                  control={control}
                  errors={errors}
                  name="interventionIds"
                  options={interventions}
                  label="Interventions"
                  isMulti
                  hideSelectedOptions={false}
                  openMenuOnFocus
                  isNarrow
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container item sm={12} spacing={2} className={styles.buttonHolder}>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" type="submit">
            {visit && visitId ? 'Update' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default Form;
