import { action } from 'easy-peasy';
import initStore from '../initStore';
import { IActionResetCoordinateStore } from '../types';

const resetCoordinateStore: IActionResetCoordinateStore = action(state => {
  state.current = initStore.current;
  state.activeChannelId = initStore.activeChannelId;
  state.channels = initStore.channels;
  state.messages = initStore.messages;
});

export default resetCoordinateStore;
