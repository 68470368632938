import { action } from 'easy-peasy';
import unionBy from 'lodash/unionBy';
import sortByName from '~/ui/pages/Reports/helpers/sortByName';
import { IActionSetClientOptions } from '../types';

const setClientOptions: IActionSetClientOptions = action((state, payload) => {
  const { items, total, archived } = payload;

  const newItems = (unionBy([...items, ...state.clientOptions.list], 'id') || [])
    .sort(sortByName)
    .filter(
      item =>
        (!state.clientOptions.filters?.teamIds?.length ||
          state.clientOptions.filters?.teamIds?.includes(item.team.id)) &&
        !archived?.includes(item.id), // exclude archived clients
    );

  state.clientOptions.list = newItems;

  if (state.clientOptions.isAllSelected && !state.clientOptions.excluded.length) {
    state.clientOptions.current = newItems;
  }

  if (typeof total === 'number') {
    state.clientOptions.total = total;
  }
});

export default setClientOptions;
