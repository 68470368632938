import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IThunkGetUserPastVisits, IVisitsActions } from '../types';

const onGetUserPastVisits: IThunkGetUserPastVisits = thunk(
  async ({ setPastVisits }: Actions<IVisitsActions>, { requestInfo, params }) => {
    const { clinicId } = requestInfo;

    const pastVisits = await api.visits.getUserPastVisits({ clinicId }, params).then(r => r.data);

    setPastVisits(pastVisits);
  },
);

export default onGetUserPastVisits;
