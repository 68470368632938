import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';
import IconButton from '@material-ui/core/IconButton';

import { useStoreActions, useStoreState } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import useRole from '~/store/user/hooks/useRole';
import formatClientOptions from '~/utils/formatClientOptions';
import useQueryParam from '../../../pages/MedicationLogistics/hooks/useQueryParam';
import useMemoCompare from '~/utils/useMemoCompare';
import { extractErrorCode, extractErrorMessage } from '~/utils/error/error';

import Allergies from './components/Allergies';
import MonitoredBy from './components/MonitoredBy';
import MonthControls from './components/MonthControls';
import PrintableMonitoredBy from './components/PrintableMonitoredBy';
import Toggle from '~/ui/components/inputs/Toggle';
import ClientSelect from '~/ui/pages/Clients/reusable/ClientSelect';
import MainInfo from './components/MainInfo';

import { IClientDetailed } from '~/services/api/client/types';
import { IClientDetailsStore } from '~/store/clientDetails/types';
import { IGetClientRequestPayload } from '~/store/client/types';

import arrowUpIcon from '~/ui/assets/images/arrowUp.svg';
import { ReactComponent as ArrowIcon } from '~/ui/assets/images/arrowBack.svg';

import styles from './ClientInfo.module.scss';
import toggleStyles from '~/ui/components/inputs/Toggle/Toggle.module.scss';

interface IProps {
  loading: boolean;
  clientId?: number;
  teamId: number;
  onVisitsPage?: boolean;
  printable?: boolean;
  loadMore?: () => void;
}

let prevRequestPayload: IGetClientRequestPayload;

const PAST_VISITS_LABEL = 'My Past Visits';

const ClientInfo = ({
  clientId,
  teamId,
  loading,
  onVisitsPage = false,
  printable = false,
  loadMore,
}: IProps): ReactElement => {
  const query = useQuery();
  const selectedMonthQuery = query.get('selectedMonth');
  const includeDeletedQuery = query.get('includeDeleted');
  const locationGroupIdQuery = query.get('locationGroupId');

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(
    selectedMonthQuery ? new Date(selectedMonthQuery) : new Date(),
  );

  const { list } = useStoreState(store => store.client.clientOptions);
  const { current } = useStoreState(state => state.client);
  const { monitoredBy: visitsMonitoredBy } = useStoreState(state => state.visits);
  const monitoredBy = useStoreState(state => state.medicationLogistics.monitoredBy);
  const { current: user } = useStoreState(state => state.user);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);

  const { onGetClient, setClientOptions, setCurrentClientOption } = useStoreActions(
    action => action.client,
  );
  const { showError } = useStoreActions(action => action.snackbar);

  const { isActTeam, isGlobalUser, isLocalAdmin, isProgramAssistant } = useRole();
  const isAdmin = isLocalAdmin || isProgramAssistant;

  const availableClients = useMemoCompare(list, (prev, next) => prev && isEqual(prev, next));

  const locationGroupId = locationGroupIdQuery ? Number(locationGroupIdQuery) : null;
  const locationGroupClients = useMemo(
    () => locationGroups.find(group => group.locationGroup.id === locationGroupId)?.clients || [],
    [locationGroups, locationGroupId],
  );

  const allClients = useMemo(
    () => (locationGroupId ? locationGroupClients : availableClients),
    [locationGroupId, locationGroupClients, availableClients],
  );

  const formattedClientOptions = useMemo(() => formatClientOptions(allClients), [allClients]);

  const hasPastVisits = (isActTeam || isGlobalUser) && onVisitsPage;

  const clientsOptions = useMemo(
    () =>
      hasPastVisits
        ? [{ value: -1, label: PAST_VISITS_LABEL }, ...formattedClientOptions]
        : formattedClientOptions,
    [formattedClientOptions, hasPastVisits],
  );

  const isPastVisits = (!clientId || clientId === -1) && hasPastVisits;

  const isPrevSelectedExist: boolean = useMemo(
    () => !!clientsOptions.find(c => c.value === clientId)?.value || isPastVisits,
    [clientsOptions, isPastVisits, clientId],
  );

  const firstOption = useMemo(() => clientsOptions?.[0]?.value, [clientsOptions]);
  const secondOption = useMemo(() => clientsOptions?.[1]?.value, [clientsOptions]);

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      client: clientId,
      includeDeleted: isAdmin ? includeDeletedQuery === 'true' : undefined,
    },
  });

  const { client, includeDeleted } = watch();

  useEffect(() => {
    if (!isPrevSelectedExist && firstOption) {
      setValue('client', Number(firstOption));
    }
  }, [isPrevSelectedExist, firstOption, setValue]);

  useEffect(() => {
    if (current?.isArchived && !includeDeleted && secondOption) {
      setCurrentClientOption([]);
      setValue('client', Number(secondOption));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current?.isArchived, includeDeleted, setClientOptions, secondOption, setValue]);

  const diffClients = useMemo(() => client !== current?.id, [client, current?.id]);

  const selectedClientTeamId = useMemo(
    () => availableClients.find(c => c.id === client)?.team?.id,
    [availableClients, client],
  );

  // as a global user we need to get team id from selected client option to fetch extended client info
  // for all other roles we pass it from parent component
  const clientTeamId = isGlobalUser ? selectedClientTeamId : teamId;

  const needFetchClient = useMemo(() => {
    if ((client > 0 || diffClients) && clientTeamId) {
      prevRequestPayload = null;
      // validate that selected client and client team id match to prevent wrong requests
      return !!availableClients.find(item => item.id === client && item.team.id === clientTeamId);
    }
    return false;
  }, [availableClients, client, diffClients, clientTeamId]);

  const roleId = user?.roleId;

  const selectClient = useCallback(async () => {
    try {
      if (!needFetchClient) {
        return;
      }
      const requestPayload = {
        clinicId: String(user?.clinic.id),
        teamId: String(clientTeamId),
        clientId: String(client),
        roleId,
      };

      if (isEqual(prevRequestPayload, requestPayload)) {
        return;
      }

      await onGetClient(requestPayload);
      prevRequestPayload = requestPayload;
    } catch (e) {
      if (extractErrorCode(e) !== 'entity_not_found' && extractErrorCode(e) !== 'forbidden') {
        showError(extractErrorMessage(e));
      }
    }
  }, [needFetchClient, user?.clinic.id, clientTeamId, client, roleId, onGetClient, showError]);

  useEffect(() => {
    selectClient();
  }, [selectClient]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useQueryParam('clientId', String(client));
  useQueryParam('selectedMonth', format(selectedMonth, 'yyyy-MM-dd'));
  useQueryParam('includeDeleted', isAdmin ? String(!!includeDeleted) : undefined);

  const currentClientIndex = useMemo(
    () => clientsOptions.findIndex(option => option.value === clientId),
    [clientsOptions, clientId],
  );

  const isLastClientOption = useMemo(
    () => clientsOptions.length - 1 === currentClientIndex,
    [clientsOptions, currentClientIndex],
  );
  const isFirstClientOption = currentClientIndex === 0;

  const onClickBack = () => {
    const newClientId = currentClientIndex
      ? clientsOptions[currentClientIndex - 1]?.value
      : clientsOptions[0]?.value;

    if (newClientId) {
      setValue('client', Number(newClientId));
    }
  };
  const onClickForward = () => {
    const newClientId =
      clientsOptions.length - 1 !== currentClientIndex
        ? clientsOptions[currentClientIndex + 1]?.value
        : clientsOptions[clientsOptions.length - 1]?.value;

    if (newClientId) {
      setValue('client', Number(newClientId));
    }
  };

  const { allergies } = (current as IClientDetailed & IClientDetailsStore) || {};

  return (
    <Box
      className={classNames(styles.infoSection, {
        [styles.collapsedInfoSection]: collapsed,
        [styles.contentEnd]: isPastVisits,
      })}
    >
      <>
        {!isPastVisits ? <MainInfo collapsed={collapsed} onVisitsPage={onVisitsPage} /> : null}
        {!collapsed && (
          <>
            {current && !isPastVisits ? (
              <>
                <MonitoredBy monitoredBy={onVisitsPage ? visitsMonitoredBy : monitoredBy} />
                {!onVisitsPage && <PrintableMonitoredBy monitoredBy={monitoredBy} />}{' '}
              </>
            ) : null}
            {!!allergies && !isPastVisits && <Allergies allergies={allergies} />}
            {isPastVisits && (
              <Box
                className={classNames(styles.column, styles.centerItems, {
                  [styles.left]: isPastVisits,
                })}
              >
                <MonthControls
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  noClient
                />
              </Box>
            )}
            <Box
              className={classNames(styles.column, styles.centerItems, {
                [styles.right]: isPastVisits,
              })}
            >
              <Box flexDirection="row" alignItems="center" display="flex" width="100%">
                <IconButton
                  onClick={onClickBack}
                  disabled={isFirstClientOption}
                  style={{ opacity: isFirstClientOption ? 0.3 : 1 }}
                >
                  <ArrowIcon width={10} height={10} />
                </IconButton>
                <ClientSelect
                  control={control}
                  errors={errors}
                  loading={loading}
                  loadMore={loadMore}
                  additionalOption={
                    hasPastVisits ? { value: -1, label: PAST_VISITS_LABEL } : undefined
                  }
                />
                <IconButton
                  className={styles.arrowRight}
                  onClick={onClickForward}
                  disabled={isLastClientOption}
                  style={{ opacity: isLastClientOption ? 0.3 : 1 }}
                >
                  <ArrowIcon width={10} height={10} />
                </IconButton>
              </Box>
              {isAdmin && !printable && (
                <Box
                  marginTop={1}
                  className={classNames(styles.toggle, toggleStyles.toggleContainer)}
                  alignItems="center"
                >
                  <Toggle
                    name="includeDeleted"
                    size="small"
                    control={control}
                    label="Display Archived Clients"
                    errors={errors}
                    labelPlacement="start"
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </>
      {!isPastVisits && (
        <Box
          className={classNames(styles.collapseButton, { [styles.collapsedIcon]: collapsed })}
          onClick={handleCollapse}
        >
          <img src={arrowUpIcon} alt="arrow-up" />
        </Box>
      )}
    </Box>
  );
};

export default memo(ClientInfo);
