import { ReactElement, ReactNode } from 'react';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import Chip from '~/ui/components/common/Chip';

import extractDateFromString from '~/utils/date/extractDateFromString';

import editIcon from '~/ui/assets/images/editGrey.svg';
import injectionIcon from '~/ui/assets/images/injection.svg';
import { ReactComponent as PreviewIcon } from '~/ui/assets/images/previewFile.svg';

import styles from './ListItem.module.scss';
import chipStyles from '~/ui/components/common/Chip/Chip.module.scss';

interface IProps {
  label: string | ReactElement;
  dose?: string;
  content?: string | ReactNode;
  showArchived?: boolean;
  injectable?: boolean;
  outsideFacility?: boolean;
  showEdit?: boolean;
  showPreview?: boolean;
  showRestore?: boolean;
  noActions?: boolean;
  className?: any;
  startIcon?: ReactNode;
  chip?: ReactNode;
  additionalText?: string;
  moreLabelWidth?: boolean;
  labelGrey?: boolean;
  disableArchived?: boolean;
  startDate?: string;
  endDate?: string;
  onArchive?: () => void;
  onEdit?: () => void;
  onRestore?: () => void;
  onPreview?: () => void;
}

const ListItem = ({
  label,
  content,
  showArchived,
  disableArchived,
  showEdit,
  showPreview,
  showRestore,
  dose,
  injectable,
  outsideFacility,
  noActions,
  className,
  startIcon,
  chip,
  additionalText = '',
  moreLabelWidth = false,
  labelGrey,
  startDate,
  endDate,
  onArchive,
  onEdit,
  onRestore,
  onPreview,
}: IProps): ReactElement => (
  <div className={classNames(styles.item, className)}>
    <div className={styles.contentWrapper}>
      <div
        className={classNames(styles.label, {
          [styles.grey]: showRestore || labelGrey,
          [styles.moreLabelWidth]: moreLabelWidth,
        })}
      >
        {startIcon}
        {label}
      </div>
      <div className={styles.column}>
        <div className={styles.row}>
          {outsideFacility && <Chip text="O.F." className={chipStyles.outlineLabel} />}
          {injectable && <img src={injectionIcon} alt="injection" className={styles.injection} />}
          <span>{dose}</span>
        </div>
        <div className={styles.content}>{content}</div>
        {!!(startDate || endDate) && (
          <div className={styles.datesRow}>
            {!!startDate && <div>Start Date: {extractDateFromString(startDate)}</div>}
            {!!endDate && <div>End Date: {extractDateFromString(endDate)}</div>}
          </div>
        )}
      </div>
    </div>
    {chip}
    {!noActions && (
      <div>
        {additionalText && <h5 className={styles.additionalText}>{additionalText}</h5>}
        {showEdit && (
          <IconButton onClick={onEdit}>
            <img src={editIcon} alt="edit" />
          </IconButton>
        )}
        {showArchived && (
          <IconButton onClick={onArchive} edge="end" disabled={disableArchived}>
            <div
              className={classNames(styles.archiveIcon, { [styles.disabled]: disableArchived })}
            />
          </IconButton>
        )}
        {showRestore && (
          <div role="presentation" onClick={onRestore} className={styles.restoreButton}>
            Restore
          </div>
        )}
        {showPreview && (
          <IconButton onClick={onPreview}>
            <PreviewIcon width={24} height={24} />
          </IconButton>
        )}
      </div>
    )}
  </div>
);

export default ListItem;
