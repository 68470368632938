import { ReactElement, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProgramAssistantTable from './Table';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import formatActTeamsOptions from '~/utils/formatActTeamsOptions';
import SimpleSelect from '~/ui/pages/MedicationLogistics/components/SimpleSelect';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import styles from './ProgramAssistant.module.scss';

type IParams = {
  id: string;
};

const ProgramAssistant = (): ReactElement => {
  const { id: clinicId } = useParams<IParams>();
  const [programAssistant, setProgramAssistant] = useState(null);
  const [teamId, setTeamId] = useState(null);

  const programAssistantPagination = useStoreState(state => state.programAssistant.pagination);

  const {
    onDeactivateProgramAssistant,
    onActivateProgramAssistant,
    onUnlockProgramAssistant,
    onReInviteProgramAssistant,
    onUnAssignProgramAssistant,
    onGetProgramAssistants,
    onArchiveProgramAssistant,
  } = useStoreActions(actions => actions.programAssistant);

  const actTeamOptions = formatActTeamsOptions(programAssistant?.teams || []);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const refetchUsers = useCallback(() => {
    onGetProgramAssistants({
      clinicId,
      params: {
        pageNumber: programAssistantPagination.pageNumber,
        pageSize: programAssistantPagination.pageSize,
      },
    });
  }, [
    clinicId,
    onGetProgramAssistants,
    programAssistantPagination.pageNumber,
    programAssistantPagination.pageSize,
  ]);

  const getConfirmProps = useCallback(
    (status: string) => {
      const payload = { clinicId, programAssistantId: programAssistant?.id };
      if (status === 'Active')
        return {
          confirmText: 'Deactivate',
          description: 'Are you sure you want to deactivate this user?',
          method: () => onDeactivateProgramAssistant(payload),
        };

      if (status === 'Deactivated')
        return {
          confirmText: 'Activate',
          description: 'Are you sure you want to activate this user?',
          method: () => onActivateProgramAssistant(payload),
        };

      if (status === 'Locked')
        return {
          confirmText: 'Unlock',
          description: 'Are you sure you want to unlock this user?',
          method: () => onUnlockProgramAssistant(payload),
        };
      if (status === 'VerifyingEmail')
        return {
          confirmText: 'Archive',
          description: 'Are you sure you want to archive this user?',
          method: async () => {
            try {
              await onArchiveProgramAssistant(payload);
              refetchUsers();
              showNotify({ message: `User successfully archived` });
            } catch (e) {
              showError(extractErrorMessage(e));
            }
          },
        };
      if (status === 'Assigned')
        return {
          confirmText: 'Unassign',
          description: `Are you sure you want to unassign ${programAssistant.firstName} ${programAssistant.lastName}?`,
          confirmDisabled: !teamId,
          customContent: (
            <div className={styles.selectContainer}>
              <span>Please select Team of this member to be unassigned.</span>
              <SimpleSelect
                label="Select Team"
                value={teamId}
                setValue={setTeamId}
                options={actTeamOptions}
              />
            </div>
          ),
          method: async () => {
            try {
              await onUnAssignProgramAssistant({
                clinicId,
                programAssistantId: programAssistant?.id,
                teamId,
              });
              refetchUsers();
              showNotify({ message: `User successfully unassigned` });
            } catch (e) {
              showError(extractErrorMessage(e));
            }
          },
        };

      return {
        confirmText: 'Resend',
        description: 'Are you sure you want to resend invitation?',
        method: () =>
          onReInviteProgramAssistant({ clinicId, programAssistantId: programAssistant?.id }),
      };
    },
    [
      clinicId,
      programAssistant?.id,
      programAssistant?.firstName,
      programAssistant?.lastName,
      teamId,
      actTeamOptions,
      onDeactivateProgramAssistant,
      onActivateProgramAssistant,
      onUnlockProgramAssistant,
      onArchiveProgramAssistant,
      refetchUsers,
      showNotify,
      showError,
      onUnAssignProgramAssistant,
      onReInviteProgramAssistant,
    ],
  );

  const { confirmText, description, customContent, confirmDisabled, method } = getConfirmProps(
    programAssistant?.status,
  );

  const onConfirm = async () => {
    try {
      await method();
      setProgramAssistant(false);
    } catch (e) {
      setProgramAssistant(extractErrorMessage(e));
    } finally {
      setProgramAssistant(false);
    }
  };

  return (
    <>
      <ProgramAssistantTable clinicId={clinicId} setProgramAssistant={setProgramAssistant} />
      {programAssistant && (
        <ConfirmModal
          description={description}
          confirmText={confirmText}
          disabled={confirmDisabled}
          customContent={customContent}
          onClose={() => {
            setProgramAssistant(null);
            setTeamId(null);
          }}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default ProgramAssistant;
