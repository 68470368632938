import { ReactElement } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import Button from '~/ui/components/common/Button';
import Tooltip from '~/ui/components/common/Tooltip';
import Textarea from '~/ui/components/inputs/Textarea';

import validate from './validate';

import { IAiTestRequestFormValues } from '../../types';

import { ReactComponent as InfoIcon } from '~/ui/assets/images/info.svg';

import {
  testAiResponseDescription,
  userPromptDescription,
  userPromptPlaceholder,
} from '../../constants';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './TestAiRequestForm.module.scss';

interface IProps {
  title: string;
  loading?: boolean;
  className?: string;
  testAiResponse: string | null;
  formClassName?: string;
  onSubmit: (val: IAiTestRequestFormValues) => void;
}

const TestAiRequestForm = ({
  title,
  loading,
  className,
  testAiResponse,
  formClassName,
  onSubmit,
}: IProps): ReactElement => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<IAiTestRequestFormValues>({ mode: 'onChange', resolver: validate });

  const { userPrompt } = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClassName}>
      <Paper className={classNames(styles.aiContainer, className)}>
        <div className={styles.row}>
          <h2 className={styles.title}>{title}</h2>
        </div>

        <div className={styles.inputWrapper}>
          <Textarea
            register={register}
            errors={errors}
            name="userPrompt"
            label="User Prompt"
            placeholder={`Example: ${userPromptPlaceholder}`}
          />
          <div>
            <Tooltip
              icon={<InfoIcon color={variables.colorBlack} width={20} />}
              body={<Typography>{userPromptDescription}</Typography>}
              className={styles.formTooltip}
            />
          </div>
        </div>
        {!!testAiResponse && (
          <div className={classNames(styles.inputWrapper, styles.aiResponseWrapper)}>
            <div>{testAiResponse}</div>
            <div>
              <Tooltip
                icon={<InfoIcon color={variables.colorBlack} width={20} />}
                body={<Typography>{testAiResponseDescription}</Typography>}
                className={styles.formTooltip}
              />
            </div>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={loading}
            loaderColor={variables.colorWhite}
            disabled={!userPrompt || loading}
          >
            Send
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default TestAiRequestForm;
