import { IAllocationClientsByGroup } from '~/services/api/clientAllocation/types';
import { IClientOption } from '~/store/client/types';

const extractLocationGroupTeamIdsByClients = (
  locationGroups: IAllocationClientsByGroup[],
  ids: number[],
): { [key: number]: IClientOption[] } =>
  locationGroups
    ?.flatMap(item => item.clients)
    .filter(client => ids.includes(client.id))
    .reduce((acc: { [key: number]: IClientOption[] }, obj) => {
      acc[obj.team.id] = [...(acc[obj.team.id] || []), obj];

      return acc;
    }, {});

export default extractLocationGroupTeamIdsByClients;
