import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IProgramAssistantActions, IThunkOnArchiveProgramAssistant } from '../types';

const onArchiveProgramAssistant: IThunkOnArchiveProgramAssistant = thunk(
  async ({ archiveProgramAssistant }: Actions<IProgramAssistantActions>, payload) => {
    const { clinicId, programAssistantId } = payload;

    await api.programAssistant.archiveProgramAssistant(clinicId, String(programAssistantId));

    archiveProgramAssistant(programAssistantId);
  },
);

export default onArchiveProgramAssistant;
