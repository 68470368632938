import onGetClientAllocations from './onGetClientAllocations';
import onGetClientAllocation from './onGetClientAllocation';
import onGetOwnClientAllocation from './onGetOwnClientAllocation';
import onGetAllocationClientsByGroup from './onGetAllocationClientsByGroup';
import onGetGlobalUserClientAllocations from './onGetGlobalUserClientAllocations';
import onGetAllocationClientsByGroupGU from './onGetAllocationClientsByGroupGU';
import onGetOwnClientAllocationGU from './onGetOwnClientAllocationGU';
import onGetClientAllocationGU from './onGetClientAllocationGU';

export default {
  onGetClientAllocations,
  onGetClientAllocation,
  onGetOwnClientAllocation,
  onGetAllocationClientsByGroup,
  onGetGlobalUserClientAllocations,
  onGetAllocationClientsByGroupGU,
  onGetOwnClientAllocationGU,
  onGetClientAllocationGU,
};
