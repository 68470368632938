import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import unionBy from 'lodash/unionBy';
import { ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import { useStoreActions } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import compareRoles from '~/utils/compareRoles';
import { extractErrorCode, extractErrorMessage } from '~/utils/error/error';

import { IUserRole } from '~/types';
import companyLogo from '~/ui/assets/images/logo.svg';
import { CHANGE_PASSWORD, FORGOT_PASSWORD, SELECT_ROLE } from '~/ui/constants/paths';
import styles from './SignIn.module.scss';
import { initialValues, validate } from './form';
import { IFormValues } from './form/types';
import ErrorCodes from './models/ErrorCodes';

const SignInForm = (): ReactElement => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: validate,
    defaultValues: initialValues,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const { email } = watch();

  const onLogin = useStoreActions(actions => actions.user.onLogin);

  const navigateToChangePassword = () => {
    navigate(CHANGE_PASSWORD, {
      state: {
        email,
      },
    });
  };

  const onSubmit = async (values: IFormValues) => {
    setIsLoading(true);

    try {
      // get user assigned roles and teams
      const { roles } = await api.auth
        .loginTeams({ ...values, email: values.email?.trim() })
        .then(response => response.data);
      const multipleRoles = roles.length > 1;
      const multipleClinic =
        unionBy(
          roles.map(role => role.clinic),
          'id',
        ).length > 1;
      const multipleTeams = !multipleRoles && roles[0]?.teams?.length > 1;
      const isSingleProgramAssistantRole =
        !multipleRoles && compareRoles(roles[0]?.name, IUserRole.ProgramAssistant);
      const isSingleGlobalUserRole =
        !multipleRoles && compareRoles(roles[0]?.name, IUserRole.GlobalUser);
      const isSingleLocalAdminRole =
        !multipleRoles && compareRoles(roles[0]?.name, IUserRole.LocalAdmin);

      // if multiple roles/clinics/teams navigate to selection screen
      if (
        multipleRoles ||
        multipleClinic ||
        (multipleTeams &&
          !isSingleProgramAssistantRole &&
          !isSingleLocalAdminRole &&
          !isSingleGlobalUserRole)
      ) {
        navigate(SELECT_ROLE, {
          state: {
            roles,
            ...values,
          },
        });
      } else {
        // if single role and team perform standard login with teamId and roleId
        await onLogin({
          roleId: roles[0]?.id,
          clinicId: roles[0]?.clinic?.id,
          teamId: roles[0]?.teams[0]?.id || null,
          ...values,
        });
      }
    } catch (e) {
      if (extractErrorCode(e) === ErrorCodes.PASSWORD_EXPIRED) {
        navigateToChangePassword();
      }
      setServerError(extractErrorMessage(e));
      setIsLoading(false);
    }
  };

  return (
    <>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      <Paper className={styles.signIn}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.title}>Log In</div>
          <div className={styles.row}>
            <Input
              name="email"
              register={register}
              errors={errors}
              size="medium"
              label="Email"
              placeholder="Email Address"
            />
          </div>
          <div className={styles.row}>
            <Input
              type="password"
              name="password"
              register={register}
              errors={errors}
              size="medium"
              label="Password"
              placeholder="Password"
            />
          </div>
          <div className={styles.row}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
              className={styles.btn}
              disabled={isLoading}
            >
              Log In
            </Button>
          </div>
          <div className={styles.divider} />
          <p className={styles.forgotPasswordText}>
            Forgot your password?
            <button
              type="button"
              className={styles.forgotPasswordButton}
              onClick={() => navigate(FORGOT_PASSWORD)}
            >
              Reset
            </button>
          </p>
          {serverError && <div className={styles.serverError}>{serverError}</div>}
          {isLoading && <Loader />}
        </form>
      </Paper>
    </>
  );
};

export default SignInForm;
