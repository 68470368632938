enum Features {
  TREATMENT_PLAN = 'TreatmentPlan',
  TOXICOLOGY = 'Toxicology',
  BIDIRECTIONAL = 'BiDirectionalVisitNote',
  DOCUMENTS = 'Documents',
  OPEN_AI = 'OpenAi',
}

export const featureLabels: { [key: string]: string } = {
  [Features.TREATMENT_PLAN]: 'Treatment Plan',
  [Features.TOXICOLOGY]: 'Toxicology',
  [Features.BIDIRECTIONAL]: 'Bidirectional',
  [Features.DOCUMENTS]: 'Documents',
  [Features.OPEN_AI]: 'Open AI',
};

export const featureList = [
  Features.TREATMENT_PLAN,
  Features.TOXICOLOGY,
  Features.BIDIRECTIONAL,
  Features.DOCUMENTS,
  Features.OPEN_AI,
];

export default Features;
