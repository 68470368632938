import { IDictionary } from '../dictionaries/types';
import { privateAxios } from '../requestsCollection';
import { IRequestInfo, IResponse } from '../types';
import {
  IDocument,
  IDocumentFilters,
  IPastVisitRequestParams,
  IUserVisitsResponse,
  IVisit,
  IVisitRequestParams,
  IVisitRequestPayload,
  IVisitsRequestInfo,
  IVisitsTotals,
} from './types';

export const getVisits = (
  { clinicId, teamId, clientId, includeDeleted = false }: IVisitsRequestInfo,
  params: IVisitRequestParams,
): IResponse<{
  visits: { items: IVisit[]; total: number };
  totals: IVisitsTotals;
  monitoredBy: IDictionary[];
}> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/overview?clientId=${clientId}&includeDeleted=${includeDeleted}`,
    params,
  });

export const getClientDocuments = (
  { clinicId, teamId, clientId }: IVisitsRequestInfo,
  params: IDocumentFilters,
): IResponse<{ items: IDocument[]; total: number }> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/document`,
      params,
    },
    'v2/',
  );

export const getPastVisits = (
  { clinicId, teamId }: IVisitsRequestInfo,
  { year, month }: IPastVisitRequestParams,
): IResponse<IUserVisitsResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/past-with-stats?year=${year}&month=${month}&PageSize=500&PageNumber=1&Filtering=Date&Sorting=Date`,
  });

export const getUserPastVisits = (
  { clinicId }: IVisitsRequestInfo,
  { year, month }: IPastVisitRequestParams,
): IResponse<IUserVisitsResponse> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/visits/past-with-stats?year=${year}&month=${month}&PageSize=500&PageNumber=1&Filtering=Date&Sorting=Date`,
  });

export const getVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<IVisit> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
  });

export const addVisit = (
  { clinicId, teamId }: IVisitsRequestInfo,
  data: IVisitRequestPayload,
): IResponse<IVisit> =>
  privateAxios({ method: 'post', url: `clinic/${clinicId}/act-team/${teamId}/visits`, data });

export const updateVisit = (
  { clinicId, teamId, visitId }: IVisitsRequestInfo,
  data: IVisitRequestPayload,
): IResponse<IVisit> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
    data,
  });

export const archiveVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<null> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}`,
  });

export const restoreVisit = ({ clinicId, teamId, visitId }: IVisitsRequestInfo): IResponse<null> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/visits/${visitId}/recover`,
  });

export const getVisitsReport = (
  { clinicId, teamId }: IRequestInfo,
  {
    clientId,
    startDate,
    endDate,
    allVisits,
    timeZoneOffset,
  }: {
    clientId: string;
    startDate: string;
    endDate: string;
    allVisits: boolean;
    timeZoneOffset: number;
  },
): IResponse<BlobPart> =>
  privateAxios({
    method: 'get',
    url:
      `clinic/${clinicId}/act-team/${teamId}/visits/report` +
      `?clientId=${clientId}${startDate ? `&StartDate=${startDate}` : ''}${
        endDate ? `&EndDate=${endDate}` : ''
      }&AllVisits=${allVisits}&timeZoneOffset=${timeZoneOffset}`,
    responseType: 'blob',
    headers: { Accept: 'application/pdf' },
  });

export default {};
