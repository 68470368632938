import { ReactElement, useCallback, useEffect, useState } from 'react';

import Loader from '~/ui/components/common/Loader';
import AiSettingsForm from '~/ui/pages/AiManagement/components/AiSettingsForm';
import TestAiRequest from '~/ui/pages/AiManagement/components/TestAiRequest';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import { IAiSettingsFormValues } from '~/ui/pages/AiManagement/types';

const AiClinicSettings = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const { onGetAiClinicSettings, onUpdateAiClinicSettings, onGetAiSystemSettings } =
    useStoreActions(actions => actions.ai);
  const { showError, showNotify } = useStoreActions(action => action.snackbar);
  const { currentClinic, currentSystem } = useStoreState(store => store.ai);
  const { current } = useStoreState(store => store.clinic);

  const handleSaveAiSettings = async (values: IAiSettingsFormValues) => {
    try {
      setSaving(true);
      await onUpdateAiClinicSettings({
        ...values,
        maxTokens: Number(values.maxTokens),
        clinicId: current.id,
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setSaving(false);
      showNotify({ message: `AI settings successfully updated` });
    }
  };

  const getAiClinicSettings = useCallback(async () => {
    try {
      setLoading(true);
      await onGetAiClinicSettings(current.id);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [current.id, onGetAiClinicSettings, showError]);

  useEffect(() => {
    if (current?.id) {
      getAiClinicSettings();
    }
  }, [current?.id, getAiClinicSettings]);

  const getAiSystemSettings = useCallback(async () => {
    try {
      await onGetAiSystemSettings();
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [onGetAiSystemSettings, showError]);

  useEffect(() => {
    getAiSystemSettings();
  }, [getAiSystemSettings]);

  const aiSettings = currentClinic || currentSystem;

  return loading ? (
    <Loader />
  ) : (
    <>
      <AiSettingsForm
        title="AI Settings"
        onSubmit={handleSaveAiSettings}
        defaultValues={currentClinic}
        loading={saving}
      />
      {aiSettings && <TestAiRequest aiSettings={aiSettings} />}
    </>
  );
};

export default AiClinicSettings;
