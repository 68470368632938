import activateProgramAssistant from './activateProgramAssistant';
import deactivateProgramAssistant from './deactivateProgramAssistant';
import setCurrentProgramAssistant from './setCurrentProgramAssistant';
import setProgramAssistants from './setProgramAssistants';
import reInviteProgramAssistant from './reInviteProgramAssistant';
import archiveProgramAssistant from './archiveProgramAssistant';

export default {
  setCurrentProgramAssistant,
  setProgramAssistants,
  activateProgramAssistant,
  deactivateProgramAssistant,
  reInviteProgramAssistant,
  archiveProgramAssistant,
};
