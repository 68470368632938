import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { IAiSettingsRequest, IAiSettings } from './types';

export const getAiSettings = (
  clinicId?: number,
): IResponse<{ exists: boolean; config: IAiSettings }> =>
  privateAxios({
    method: 'get',
    url: `openai${clinicId ? `/clinic/${clinicId}` : ''}/settings`,
  });

export const updateAiSettings = ({
  clinicId,
  ...data
}: IAiSettingsRequest): IResponse<IAiSettings> =>
  privateAxios({
    method: 'post',
    url: `openai${clinicId ? `/clinic/${clinicId}` : ''}/settings`,
    data,
  });

export const sendAiRequest = (
  data: IAiSettings & { userPrompt: string },
): IResponse<{ results: string }> =>
  privateAxios({
    method: 'post',
    url: 'openai/test',
    data,
  });

export default {};
