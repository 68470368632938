import onGetAiSystemSettings from './onGetAiSystemSettings';
import onGetAiClinicSettings from './onGetAiClinicSettings';
import onUpdateAiSystemSettings from './onUpdateAiSystemSettings';
import onUpdateAiClinicSettings from './onUpdateAiClinicSettings';
import onSendAiRequest from './onSendAiRequest';

export default {
  onGetAiSystemSettings,
  onGetAiClinicSettings,
  onUpdateAiSystemSettings,
  onUpdateAiClinicSettings,
  onSendAiRequest,
};
