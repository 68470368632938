import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import { setPasswordExpiration, setSendbirdData, setTokens } from '~/services/localStorage/auth';

import { IThunkLogin, IUserActions } from '../types';
import { MemberType, memberTypeLabels } from '~/ui/constants/memberTypes';

const onLogin: IThunkLogin = thunk(
  async ({ setUser, setAuthorized, setRole }: Actions<IUserActions>, payload) => {
    const {
      accessToken,
      refreshToken,
      teamId,
      roleId,
      clinicId,
      passwordExpiresSoon,
      daysTillExpiration,
    } = await api.auth.login(payload).then(response => response.data);
    setTokens({ accessToken, refreshToken });
    const user = await api.auth.getCurrentUser().then(response => response.data);

    const globalUserRole = user.roles
      .filter(role => role.clinic?.id === clinicId)
      .find(role => role.name === memberTypeLabels[MemberType.GLOBAL_USER]);

    const isGlobalUser = globalUserRole?.id === roleId;

    setUser({ ...user, roleId });
    setRole({ teamId: isGlobalUser ? null : teamId, roleId, clinicId });
    setPasswordExpiration({ passwordExpiresSoon, daysTillExpiration });
    const { data } = await api.auth.fetchSendbirdToken();
    setSendbirdData(data);
    setAuthorized({ authorized: true });
  },
);

export default onLogin;
