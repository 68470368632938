export enum IUserRole {
  SuperAdmin = 'Super Admin',
  LocalAdmin = 'Local Admin',
  ActTeamLeader = 'ACT Team Leader',
  ActTeamMember = 'ACT Team Member',
  ProductOwner = 'Product Owner',
  ProgramAssistant = 'Program Assistant',
  GlobalUser = 'Global User',
}

export interface IOption {
  value: string | number;
  label: string;
  description?: string;
  isDisabled?: boolean;
}
