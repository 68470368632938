import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useStoreState } from '~/store/hooks';
import useRole from '../../store/user/hooks/useRole';
import { CLINIC_MANAGEMENT, MY_CLINIC, REPORTS } from '~/ui/constants/paths';
/**
 * Render public route
 * Only unlogged users can access to public route
 * Otherwise - redirect user to another allowed page route
 */
const PublicRoute = ({ children = null }: { children?: React.ReactNode }): ReactElement => {
  const { authorized, authChecked } = useStoreState(state => state.user);

  const {
    isSuperAdmin,
    isProductOwner,
    isProgramAssistant,
    isLocalAdmin,
    isActTeam,
    isGlobalUser,
  } = useRole();

  if (!authChecked) return null;

  const getRedirectToLink = () => {
    if (isProductOwner || isSuperAdmin) {
      return CLINIC_MANAGEMENT;
    }

    if (isProgramAssistant || isLocalAdmin || isActTeam || isGlobalUser) {
      return REPORTS;
    }
    return MY_CLINIC;
  };

  const to = getRedirectToLink();

  return authorized ? (
    <>
      <Navigate to={to} replace />
    </>
  ) : (
    <>{children}</>
  );
};

export default PublicRoute;
