import { Actions, thunk } from 'easy-peasy';

import {
  deletePasswordExpiration,
  deleteSendbirdData,
  deleteTokens,
  deleteUserData,
} from '~/services/localStorage/auth';
import { clearAllFilters } from '~/services/localStorage/filters';
import { IClientActions } from '~/store/client/types';
import { IReportsActions } from '~/store/reports/types';
import { IThunkLogout, IUserActions } from '../types';
import { ISystemAlertActions } from '~/store/systemAlert/types';
import { IResourceActions } from '~/store/resource/types';
import { IActTeamActions } from '~/store/actTeam/types';
import { ICoordinateActions } from '~/store/coordinate/types';

const onLogout: IThunkLogout = thunk(
  async ({
    resetUser,
    setAuthorized,
    resetReportsStore,
    resetSystemAlertsStore,
    resetClientStore,
    resetResourceStore,
    resetActTeamStore,
    resetCoordinateStore,
  }: Actions<
    IUserActions &
      Pick<IReportsActions, 'resetReportsStore'> &
      Pick<IResourceActions, 'resetResourceStore'> &
      Pick<ISystemAlertActions, 'resetSystemAlertsStore'> &
      Pick<IActTeamActions, 'resetActTeamStore'> &
      Pick<IClientActions, 'resetClientStore'> &
      Pick<ICoordinateActions, 'resetCoordinateStore'>
  >) => {
    resetUser();
    setAuthorized({ authorized: false });
    deleteTokens();
    deletePasswordExpiration();
    deleteUserData();
    deleteSendbirdData();
    resetReportsStore();
    resetResourceStore();
    resetSystemAlertsStore();
    resetCoordinateStore();
    resetClientStore();
    clearAllFilters();
    resetActTeamStore();
  },
);

export default onLogout;
