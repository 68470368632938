import { ReactElement, memo, useCallback } from 'react';
import { GroupTypeBase, MenuListComponentProps } from 'react-select';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { IOption } from '~/ui/components/inputs/Select/types';
import SelectAll from '~/ui/components/inputs/SelectWithoutAnimation/components/SelectAll';

const NewMenuList = ({
  children,
  ...props
}: MenuListComponentProps<IOption, boolean, GroupTypeBase<IOption>>): ReactElement => {
  const { excluded, isAllSelected, list } = useStoreState(store => store.client.clientOptions);
  const { setCurrentClientOption, setAllClientOption, setExcludedClientOption } = useStoreActions(
    action => action.client,
  );

  const selectAll = isAllSelected && !excluded.length;

  const setAll = useCallback(
    (all?: boolean) => {
      if (all) {
        setAllClientOption(true);
        setExcludedClientOption([]);
        setCurrentClientOption(list);
      } else {
        setAllClientOption(false);
        setCurrentClientOption([]);
        setExcludedClientOption([]);
      }
    },
    [list, setAllClientOption, setCurrentClientOption, setExcludedClientOption],
  );

  return (
    <SelectAll {...props} selectAll={selectAll} setSelectAll={setAll}>
      {children}
    </SelectAll>
  );
};

export default memo(NewMenuList);
