import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { ILocalAdminActions, IThunkArchiveLocalAdmin } from '../types';

const onArchiveLocalAdmin: IThunkArchiveLocalAdmin = thunk(
  async ({ archiveLocalAdmin }: Actions<ILocalAdminActions>, payload) => {
    const { clinicId, localAdminId } = payload;

    await api.localAdmin.archiveLocalAdmin(clinicId, localAdminId);

    archiveLocalAdmin(localAdminId);
  },
);

export default onArchiveLocalAdmin;
