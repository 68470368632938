import { thunk } from 'easy-peasy';

import api from '~/services/api';
import { IThunkArchiveTeamMember } from '../types';

const onArchiveTeamMember: IThunkArchiveTeamMember = thunk(async (_, payload) => {
  const { clinicId, actTeamId, teamMemberId } = payload;

  await api.actTeamMember.archiveTeamMember(clinicId, actTeamId, teamMemberId);
});

export default onArchiveTeamMember;
