import Checkbox from '@material-ui/core/Checkbox';
import { ReactElement } from 'react';

import { featureLabels, featureList } from '~/ui/constants/features';

import styles from './ClinicProfile.module.scss';

interface IClinicFeaturesListProps {
  features: string[];
}

const ClinicFeaturesList = ({ features }: IClinicFeaturesListProps): ReactElement => {
  const renderItem = (feature: string) => (
    <div className={styles.item}>
      <span className={styles.itemHeader}>{featureLabels[feature]}</span>
      <span className={styles.itemDetails}>
        <Checkbox checked={features.includes(feature)} disabled color="primary" size="small" />
      </span>
    </div>
  );

  return (
    <div className={styles.itemsWrapper}>
      <div className={styles.item}>
        <h3 className={styles.header}>Features</h3>
      </div>
      {featureList.map(renderItem)}
    </div>
  );
};

export default ClinicFeaturesList;
