import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IAiActions, IThunkGetAiClinicSettings } from '../types';

const onGetAiClinicSettings: IThunkGetAiClinicSettings = thunk(
  async ({ setAiClinicSettings, resetAiClinicSettings }: Actions<IAiActions>, clinicId) => {
    resetAiClinicSettings();
    const data = await api.ai.getAiSettings(clinicId).then(r => r.data);

    setAiClinicSettings(data.config);
  },
);

export default onGetAiClinicSettings;
