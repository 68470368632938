import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ReactElement, Suspense, lazy, useCallback, useEffect, useState } from 'react';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';

import { extractErrorMessage } from '~/utils/error/error';
import useRole from '../../../store/user/hooks/useRole';

import styles from './Notifications.module.scss';

const SOSAlerts = lazy(() => import('./components/SOSAlerts'));
const DeleteRequestsTable = lazy(() => import('./components/DeleteRequestsTable'));
const SystemAlerts = lazy(() => import('./components/SystemAlerts'));

const Notifications = (): ReactElement => {
  const { isActTeam, isGlobalUser } = useRole();
  const hasAccess = isActTeam || isGlobalUser;

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(hasAccess ? 2 : 0);

  const user = useStoreState(state => state.user.current);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamsAvailable = useStoreActions(actions => actions.actTeam.onGetActTeamsAvailable);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([
        onGetMyClinic(),
        (hasAccess ? undefined : onGetActTeamsAvailable)?.({ clinicId: String(user.clinic.id) }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [hasAccess, onGetActTeamsAvailable, onGetMyClinic, showError, user.clinic.id]);

  const renderHeader = () => (
    <div className={styles.header}>
      <h2>Notifications</h2>
    </div>
  );

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading || !user) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <div className={styles.container}>
        {renderHeader()}

        <div className={styles.tabsWrapper}>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(_e, val) => setTab(val)}
          >
            {!hasAccess && <Tab label="SOS ALERTS" value={0} />}
            {!hasAccess && <Tab label="ACCOUNT DELETE REQUESTS" value={1} />}
            <Tab label="SYSTEM ALERTS" value={2} />
          </Tabs>
        </div>
        {tab === 0 && !hasAccess && <SOSAlerts clinicId={String(user.clinic.id)} />}
        {tab === 1 && !hasAccess && <DeleteRequestsTable clinicId={String(user.clinic.id)} />}
        {tab === 2 && <SystemAlerts />}
      </div>
    </Suspense>
  );
};

export default Notifications;
