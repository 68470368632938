import onGetVisits from './onGetVisits';
import onGetVisit from './onGetVisit';
import onAddVisit from './onAddVisit';
import onEditVisit from './onEditVisit';
import onGetPastVisits from './onGetPastVisits';
import onArchiveVisit from './onArchiveVisit';
import onRestoreVisit from './onRestoreVisit';
import onGetDocuments from './onGetDocuments';
import onGetUserPastVisits from './onGetUserPastVisits';

export default {
  onGetVisits,
  onGetVisit,
  onAddVisit,
  onEditVisit,
  onGetPastVisits,
  onArchiveVisit,
  onRestoreVisit,
  onGetDocuments,
  onGetUserPastVisits,
};
