import { ReactElement } from 'react';
import { components, GroupTypeBase, OptionProps } from 'react-select';
import { IOption } from '~/types';

import Chip from '~/ui/components/common/Chip';

import extractChipText from '~/utils/text/extractChipText';

import styles from './CustomOption.module.scss';
import chipStyles from '~/ui/components/common/Chip/Chip.module.scss';
import classNames from 'classnames';

const CustomOption = ({
  label,
  isSelected,
  ...rest
}: OptionProps<IOption, boolean, GroupTypeBase<IOption>>): ReactElement => {
  const { text, chipText } = extractChipText(label);
  return (
    <components.Option
      {...rest}
      className={styles.customOption}
      isSelected={isSelected}
      label={text}
    >
      <div className={styles.row}>
        <span className={styles.label}>{text}</span>
        {!!chipText && (
          <Chip text={chipText} className={classNames(chipStyles.outlineLabel, styles.chip)} />
        )}
      </div>

      {rest.data.description && <span className={styles.description}>{rest.data.description}</span>}
    </components.Option>
  );
};

export default CustomOption;
