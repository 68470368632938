import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IActTeamMemberActions, IThunkDeactivateGlobalUser } from '../types';

const onDeactivateGlobalUser: IThunkDeactivateGlobalUser = thunk(
  async ({ deactivateTeamMember }: Actions<IActTeamMemberActions>, payload) => {
    const { clinicId, teamMemberId } = payload;

    await api.actTeamMember.deactivateGlobalUser(clinicId, teamMemberId);

    deactivateTeamMember(teamMemberId);
  },
);

export default onDeactivateGlobalUser;
