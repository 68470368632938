import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkArchiveGlobalUser } from '../types';

const onArchiveGlobalUser: IThunkArchiveGlobalUser = thunk(async (_, payload) => {
  const { clinicId, teamMemberId } = payload;

  await api.actTeamMember.archiveGlobalUser(clinicId, teamMemberId);
});

export default onArchiveGlobalUser;
