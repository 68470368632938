import setLocalAdmins from './setLocalAdmins';
import deactivateLocalAdmin from './deactivateLocalAdmin';
import activateLocalAdmin from './activateLocalAdmin';
import setLocalAdmin from './setLocalAdmin';
import archiveLocalAdmin from './archiveLocalAdmin';

export default {
  setLocalAdmins,
  activateLocalAdmin,
  deactivateLocalAdmin,
  setLocalAdmin,
  archiveLocalAdmin,
};
