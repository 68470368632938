import DocumentsOrderBy from '~/ui/pages/Clients/models/documentsOrderBy';
import {
  IBloodDrawn,
  IToxicology,
  IToxicologyResults,
} from '~/ui/pages/Visits/components/AddVisit/types';
import { IRiskAssessment, ISuicideRisk } from '../client/types';
import { IVisitTreatmentPlan, PaperWorkStatus } from '../clientDetails/types';
import { IDictionary } from '../dictionaries/types';
import { VisitStatus } from '~/store/reports/models/visitStatus';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';

export interface IVisitsRequestInfo {
  clinicId: string;
  teamId?: string;
  clientId?: string;
  visitId?: string;
  includeDeleted?: boolean;
}

export interface IVisitRequestParams {
  sorting?: string;
  filtering?: string;
  startDate?: string;
  endDate?: string;
  type?: string;
  personFiltering?: string;
  status?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface IPastVisitRequestParams extends IVisitRequestParams {
  year?: string;
  month?: string;
}

export interface IVisitParams {
  dateFrom: Date;
  dateTo: Date;
  filterBy: number;
  sortBy: number;
  pageNumber: number;
  pageSize: number;
}

export interface IVisitRequestPayload {
  clientId: number;
  clientNoShow: boolean;
  date: string;
  durationSeconds: number;
  isClientVisited: boolean;
  isHospitalStaffVisited: boolean;
  noShowReason: number;
  note?: string;
  startTime: string;
  type: string;
  visitedPeople: number[];
  visitors: number[];
  address?: {
    city: string;
    line1: string;
    line2?: string;
    zipCode: string;
    stateId: number;
  };
  treatmentPlanDetails?: {
    id?: number;
    reviewedObjectives: number[];
  };
  billable: boolean;
  sendToEmr: boolean;
  interventionIds?: number[];
  toxicologyResults: IToxicologyResults | null;
}

interface Visitor {
  id: number;
  name: string;
  photo: string;
  photoThumbnail: string;
  subRole: string;
}

export interface IVisit {
  client: IDictionary & { isArchived?: boolean };
  clientNoShow: boolean;
  date: string;
  durationSeconds: 240;
  editInformation: { name: string; date: string };
  id: number;
  isArchived: boolean;
  isClientVisited: boolean;
  isHospitalStaffVisited: boolean;
  noShowReason: IDictionary;
  note: string;
  status: VisitStatus;
  startTime: string;
  type: string;
  visitedBy: Visitor[];
  paperworkStatus: PaperWorkStatus;
  visitedPeople: [{ id: 0; name: string; relationship: string }];
  address?: {
    city?: string;
    zipCode?: string;
    line1?: string;
    line2?: string;
    state?: IDictionary;
  };
  billable: boolean;
  interventions: IDictionary[];
  treatmentPlanDetails: IVisitTreatmentPlan;
  toxicologyResults: IToxicologyResults | null;
  sendToEmr: boolean;
  isDocument?: boolean;
  documentId?: number;
}

export type IUserVisitClient = {
  aot: boolean;
  id: number;
  name: string;
  suicideRisk: ISuicideRisk;
  riskAssessment: IRiskAssessment;
  isArchived?: boolean;
  team: IDictionary;
};

export type IUserVisitsResponse = {
  visits: {
    client: IUserVisitClient;
    visits: IVisit[];
  }[];
  totals: IVisitsTotals;
};

export interface IDocument {
  id: number;
  date: string;
  type: string;
  url: string;
  code: string;
  staff: string;
}

export interface IDocumentFilters {
  name?: string;
  pageSize?: number;
  pageNumber?: number;
  documentTypeId?: number;
  sorting?: DocumentsOrderBy;
  sortDirection?: SortDirection;
}

export interface IVisitsTotals {
  billable: {
    client: number;
    hospital: number;
    collateral: number;
  };
  nonBillable: {
    client: number;
    hospital: number;
    collateral: number;
  };
}

export interface IVisitsFilters {
  sortBy: number;
  filterBy: number;
  startDate: string | null;
  endDate: string | null;
  includeDeleted?: boolean;
}

export interface IVisitForm {
  date: string;
  type: number;
  startTime: string;
  visitedPeople: number[];
  duration: string;
  visitors: number[];
  note: string;
  city?: string;
  stateId?: number;
  zipCode?: string;
  line1?: string;
  line2?: string;
  address?: number;
  clientNoShow: boolean;
  noShowReason: number;
  paperworkStatus: PaperWorkStatus;
  interventionIds?: number[];
  billable?: boolean;
  sendToEmr?: boolean;
  toxicology?: IToxicology | null;
  bloodDrawn?: IBloodDrawn | null;
}

export enum FilterType {
  PERSON = 'person',
  TYPE = 'type',
  DATE = 'date',
  STATUS = 'status',
}
