import Box from '@material-ui/core/Box';
import { ReactElement } from 'react';
import { components, GroupTypeBase, MultiValueProps } from 'react-select';

import { IOption } from '../../../Select/types';

import styles from './NewMultiValue.module.scss';

const MAX_SELECTED_TO_SHOW = 2;

const NewMultiValue = ({
  getValue,
  total,
  isAllSelected,
  ...props
}: MultiValueProps<IOption, GroupTypeBase<IOption>> & {
  total: number;
  isAllSelected: boolean;
}): ReactElement => {
  const isSingleOption = total === 1;

  const selectedIndex = getValue()?.findIndex(v => v.value === props.data.value) || 0;

  // show All chip instead of first item, if all selected
  if (isAllSelected && selectedIndex === 1 && !isSingleOption) {
    return (
      <components.MultiValue
        {...props}
        getValue={getValue}
        data={{ ...props.data, label: 'All' }}
        removeProps={{ ...props.removeProps, onClick: () => props.clearValue() }}
      >
        <components.MultiValueLabel {...props}>All</components.MultiValueLabel>
      </components.MultiValue>
    );
  }
  // hide all other chips but only first item, if all selected
  if (isAllSelected && !isSingleOption) {
    return null;
  }

  if (selectedIndex < MAX_SELECTED_TO_SHOW) {
    return <components.MultiValue {...props} getValue={getValue} />;
  }

  return selectedIndex === MAX_SELECTED_TO_SHOW ? (
    <Box className={styles.container}>
      <span>...</span>
      <Box className={styles.hiddenItemsContainer}>{total - MAX_SELECTED_TO_SHOW}</Box>
    </Box>
  ) : null;
};

export default NewMultiValue;
