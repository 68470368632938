import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { createSignalRContext } from 'react-signalr';
import { format } from 'date-fns';

import formatClientAddress from '~/utils/dataFormat/formatClientAddress';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useRole from '~/store/user/hooks/useRole';

import Button from '~/ui/components/common/Button';
import Modal from '~/ui/components/common/Modal';

import { IDictionary } from '~/services/api/dictionaries/types';
import { IAddressExtended } from '~/services/api/types';

import AlertsOrderBy from '../../models/alertsOrderBy';
import { SortDirection } from '~/ui/pages/Reports/constants/visitsSortOptions';
import { NOTIFICATIONS } from '~/ui/constants/paths';

import styles from './SignalRSOSAlert.module.scss';

export const SignalRContext = createSignalRContext({
  shareConnectionBetweenTab: true,
});

interface IAlertMessage {
  user: IDictionary;
  dateAndTime: string;
  address: IAddressExtended;
}

export const SignalRSOSAlert = (): ReactElement => {
  const [message, setMessage] = useState<IAlertMessage | null>(null);

  SignalRContext.useSignalREffect(
    'PanicRequestReceived',
    (data: IAlertMessage) => {
      setMessage(data);
    },
    [],
  );
  const user = useStoreState(state => state.user.current);
  const { list: sosAlerts } = useStoreState(state => state.sosRequests);

  const hasUnreadSosAlerts = useMemo(
    () => !!sosAlerts.filter(item => !item.isRead)?.length,
    [sosAlerts],
  );

  const { isActTeam, isGlobalUser } = useRole();

  const { onGetSosRequests } = useStoreActions(actions => actions.sosRequests);

  // fetch alerts on message arrived
  const fetchAlerts = useCallback(async () => {
    if (hasUnreadSosAlerts && user?.clinic?.id && !isActTeam && !isGlobalUser) {
      await onGetSosRequests({
        clinicId: String(user?.clinic?.id),
        params: {
          pageNumber: 1,
          pageSize: 25,
          onlyActive: true,
          sorting: AlertsOrderBy.DATE,
          sortDirection: SortDirection.Desc,
        },
      });
    }
  }, [hasUnreadSosAlerts, user?.clinic?.id, isActTeam, isGlobalUser, onGetSosRequests]);

  const { pathname } = window?.location || {};
  const isNotificationsPage = pathname === NOTIFICATIONS;

  useEffect(() => {
    if (!isNotificationsPage) {
      fetchAlerts();
    }
  }, [isNotificationsPage, fetchAlerts]);

  const resetMessage = () => {
    setMessage(null);
  };

  if (!message) {
    return null;
  }

  return (
    <Modal open={!!message} className={styles.modal} onClose={resetMessage}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalTitle}>Alert!</div>
        <p>
          {`${message.user.name} has clicked`}
          <br />
          <span>{`the SOS button${message.address ? ' from' : ''}:`}</span>
        </p>
        <p>{formatClientAddress(message.address)}</p>
        <p>{`at ${format(new Date(message.dateAndTime), "hh:mm aaa 'on' MM/dd/yyyy")}`}</p>
        <div className={styles.actionButtons}>
          <Button color="primary" variant="contained" onClick={resetMessage}>
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
};
