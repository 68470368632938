import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { IAiModel } from './types';

const ai: IAiModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default ai;
