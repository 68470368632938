import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IAiActions, IThunkOnUpdateAiClinicSettings } from '../types';

const onUpdateAiClinicSettings: IThunkOnUpdateAiClinicSettings = thunk(
  async ({ setAiClinicSettings }: Actions<IAiActions>, payload) => {
    await api.ai.updateAiSettings(payload);

    setAiClinicSettings(payload);
  },
);

export default onUpdateAiClinicSettings;
