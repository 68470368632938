import { ReactElement } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import Button from '~/ui/components/common/Button';
import Tooltip from '~/ui/components/common/Tooltip';
import Input from '~/ui/components/inputs/Input';
import Textarea from '~/ui/components/inputs/Textarea';
import Toggle from '~/ui/components/inputs/Toggle';

import validate from './validate';

import { IAiSettingsFormValues } from '../../types';
import { IAiSettings } from '~/services/api/ai/types';

import { ReactComponent as InfoIcon } from '~/ui/assets/images/info.svg';

import {
  systemPromptDescription,
  systemPromptPlaceholder,
  temperatureDescription,
  tokensDescription,
} from '../../constants';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './AiSettingsForm.module.scss';
import toggleStyles from '~/ui/components/inputs/Toggle/Toggle.module.scss';

interface IProps {
  title: string;
  loading?: boolean;
  isSystemLevel?: boolean;
  className?: string;
  formClassName?: string;
  defaultValues?: IAiSettings;
  onSubmit: (val: IAiSettingsFormValues) => void;
}

const AiSettingsForm = ({
  title,
  loading,
  className,
  formClassName,
  defaultValues,
  isSystemLevel = false,
  onSubmit,
}: IProps): ReactElement => {
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty },
  } = useForm<IAiSettingsFormValues>({ mode: 'onChange', resolver: validate, defaultValues });

  const { systemPrompt, temperature, maxTokens, active } = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={formClassName}>
      <Paper className={classNames(styles.aiContainer, className)}>
        <div className={styles.row}>
          <h2 className={styles.title}>{title}</h2>
          {!isSystemLevel && (
            <Box
              marginTop={1}
              className={classNames(styles.toggle, toggleStyles.toggleContainer)}
              alignItems="center"
            >
              <Toggle
                name="active"
                size="small"
                control={control}
                label="Active"
                errors={errors}
                labelPlacement="start"
              />
            </Box>
          )}
        </div>

        <div className={styles.inputWrapper}>
          <Textarea
            register={register}
            errors={errors}
            name="systemPrompt"
            label="System Prompt"
            placeholder={`Example: ${systemPromptPlaceholder}`}
            disabled={!active}
          />
          <div>
            <Tooltip
              icon={<InfoIcon color={variables.colorBlack} width={20} />}
              body={<Typography>{systemPromptDescription}</Typography>}
              className={styles.formTooltip}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <Input
            register={register}
            errors={errors}
            name="temperature"
            label="Temperature"
            placeholder="Example: 0.3"
            disabled={!active}
          />
          <div>
            <Tooltip
              icon={<InfoIcon color={variables.colorBlack} width={20} />}
              body={<Typography>{temperatureDescription}</Typography>}
              className={styles.formTooltip}
            />
          </div>
        </div>
        <div className={styles.inputWrapper}>
          <Input
            register={register}
            errors={errors}
            name="maxTokens"
            label="Max Tokens"
            placeholder="Example: 300"
            disabled={!active}
          />
          <div>
            <Tooltip
              icon={<InfoIcon color={variables.colorBlack} width={20} />}
              body={<Typography>{tokensDescription}</Typography>}
              className={styles.formTooltip}
            />
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            isLoading={loading}
            loaderColor={variables.colorWhite}
            disabled={!systemPrompt || !temperature || !maxTokens || !isDirty}
          >
            Save
          </Button>
        </div>
      </Paper>
    </form>
  );
};

export default AiSettingsForm;
