import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import debounce from 'lodash/debounce';
import { ReactElement, useMemo } from 'react';
import Select, { GroupTypeBase, Styles, ValueType } from 'react-select';
import { SelectComponents } from 'react-select/src/components';

import { useStoreActions, useStoreState } from '~/store/hooks';
import formatClientOptions from '~/utils/formatClientOptions';

import customStyles from '~/ui/components/inputs/Select/customStyles';
import ClearIndicator from '~/ui/components/inputs/SelectWithoutAnimation/components/ClearIndicator';
import DropdownIndicator from '~/ui/components/inputs/SelectWithoutAnimation/components/DropdownIndicator';
import NewMultiValue from '~/ui/components/inputs/SelectWithoutAnimation/components/NewMultiValue';
import Option from '~/ui/components/inputs/SelectWithoutAnimation/components/Option';
import customStylesAdditionally from '~/ui/components/inputs/SelectWithoutAnimation/customStylesAdditionally';
import NewMenuList from './components/NewMenuList';

import { IOption } from '~/types';
import { IClientOption } from '~/store/client/types';

import styles from '../../../../components/inputs/Select/Select.module.scss';

interface IClientMultiSelectProps {
  label?: string;
  loading: boolean;
  customComponents?: Partial<SelectComponents<IOption, boolean, GroupTypeBase<IOption>>>;
  loadMore?: () => void;
  additionalStyleHandler?: () => Partial<Styles<IOption, boolean, GroupTypeBase<IOption>>>;
  onSelect?: (value: IClientOption[]) => void;
}

const ClientMultiSelect = ({
  label = 'Select Client(s)',
  loading,
  customComponents,
  additionalStyleHandler,
  loadMore,
  onSelect,
}: IClientMultiSelectProps): ReactElement => {
  const {
    list: availableClients,
    total,
    current,
    excluded,
    isAllSelected,
  } = useStoreState(store => store.client.clientOptions);

  const {
    setClientOptionsFilter,
    setCurrentClientOption,
    setExcludedClientOption,
    setAllClientOption,
  } = useStoreActions(action => action.client);

  const selectedClients = useMemo(
    () => (isAllSelected ? current.filter(item => !excluded.includes(item.id)) : current),
    [isAllSelected, current, excluded],
  );

  const currentValue = useMemo(() => formatClientOptions(selectedClients), [selectedClients]);
  const clientsOptions = useMemo(() => formatClientOptions(availableClients), [availableClients]);

  const handleSelectOption = (options: ValueType<IOption | IOption[], boolean>) => {
    const optionsArray = options as IOption[];
    const optionIds = optionsArray.map(option => option.value);
    const isExcluding = isAllSelected && optionsArray.length < total;

    if (!optionsArray.length) {
      setExcludedClientOption([]);
      setCurrentClientOption([]);
      setAllClientOption(false);
      onSelect?.([]);
      return;
    }

    if (isExcluding) {
      const excludedClients = availableClients
        .filter(client => !optionIds.includes(client.id))
        .map(client => client.id);
      setExcludedClientOption(excludedClients);
    } else {
      const newClients = availableClients.filter(client => optionIds.includes(client.id));
      setCurrentClientOption(newClients);
      onSelect?.(newClients);
    }
  };

  const mergedStyles = {
    ...customStyles,
    ...customStylesAdditionally(clientsOptions, 200, '', undefined, false, 'black', true, false),
    ...(additionalStyleHandler?.() || {}),
  };

  const totalSelected = useMemo(() => {
    if (isAllSelected && !excluded.length) {
      return total;
    }
    if (isAllSelected && excluded.length) {
      return total - excluded.length;
    }
    return current?.length || 0;
  }, [current?.length, excluded.length, isAllSelected, total]);

  const setValueDebounced = debounce(name => setClientOptionsFilter({ name, pageNumber: 1 }), 500);

  return (
    <FormControl component="fieldset" className={styles.selectControl}>
      <FormLabel component="legend" className={styles.label}>
        {label}
      </FormLabel>
      <Select
        closeMenuOnSelect={false}
        isLoading={loading}
        isSearchable
        maxMenuHeight={200}
        placeholder=""
        styles={mergedStyles}
        hideSelectedOptions={false}
        isOptionDisabled={() => isAllSelected}
        name="clients"
        components={{
          Option: props => (
            <Option
              {...props}
              isSelected={
                (isAllSelected && !excluded.includes(props.data.value)) || props.isSelected
              }
              isDisabled={isAllSelected}
              loadMore={loadMore && !loading ? debounce(loadMore, 1000) : undefined}
            />
          ),
          MenuList: NewMenuList,
          MultiValue: props => (
            <NewMultiValue
              {...props}
              isAllSelected={isAllSelected && !excluded.length}
              total={totalSelected}
            />
          ),
          DropdownIndicator,
          ClearIndicator,
          ...(customComponents || {}),
        }}
        menuShouldScrollIntoView
        options={clientsOptions}
        isMulti
        value={currentValue}
        onChange={val => handleSelectOption(val)}
        onInputChange={setValueDebounced}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default ClientMultiSelect;
