import resetAiSystemSettings from './resetAiSystemSettings';
import resetAiClinicSettings from './resetAiClinicSettings';
import setAiTestResponse from './setAiTestResponse';
import setAiSystemSettings from './setAiSystemSettings';
import setAiClinicSettings from './setAiClinicSettings';

export default {
  resetAiClinicSettings,
  resetAiSystemSettings,
  setAiTestResponse,
  setAiSystemSettings,
  setAiClinicSettings,
};
