import { action } from 'easy-peasy';
import { IActionAiSettings } from '../types';

const setAiClinicSettings: IActionAiSettings = action((state, aiSettings) => {
  if (aiSettings) {
    state.currentClinic = aiSettings;
  }
});

export default setAiClinicSettings;
