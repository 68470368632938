import { ReactElement, useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

import Table from '../../reusable/Table';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import useMemoCompare from '~/utils/useMemoCompare';
import useRole from '~/store/user/hooks/useRole';
import api from '~/services/api';

import { IClient } from '~/services/api/client/types';
import { IGetTeamClientsRequestPayload } from '~/store/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import styles from './MyClients.module.scss';

let prevRequestPayload: IGetTeamClientsRequestPayload;

const MyClients = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  // a plug for Table component
  const [, setClient] = useState<IClient>(null);
  const [dictionaries, setDictionaries] = useState<{ id: number; value: string }[]>([]);

  const clinicId = useStoreState(state => state.clinic.current?.id);

  const { team, isGlobalUser } = useRole();

  const { id: teamId, name } = team || {};

  const {
    list: clients,
    pagination: { pageSize, pageNumber },
    filters,
  } = useStoreState(state => state.client);

  const memoizedFilters = useMemoCompare(filters, (prev, next) => prev && isEqual(prev, next));

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetTeamClients, onGetClinicClients } = useStoreActions(actions => actions.client);
  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetActTeamsAvailable } = useStoreActions(action => action.actTeam);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      if (!clinicId) {
        await onGetMyClinic();
      }
      if (clinicId && isGlobalUser) {
        await onGetActTeamsAvailable({ clinicId: String(clinicId) });
      }

      if (clinicId && teamId) {
        const requestPayload = {
          clinicId: String(clinicId),
          teamId: String(teamId),
          params: { ...memoizedFilters, pageSize, pageNumber },
        };

        if (isEqual(prevRequestPayload, requestPayload)) {
          setLoading(false);
          return;
        }

        await onGetTeamClients(requestPayload);
        prevRequestPayload = requestPayload;
      }
      if (!dictionaries.length) {
        const { data } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.DischargeReason,
        );
        setDictionaries(data);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clinicId,
    dictionaries.length,
    onGetMyClinic,
    onGetTeamClients,
    onGetActTeamsAvailable,
    showError,
    teamId,
    isGlobalUser,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  return (
    <div>
      <div className={styles.header}>
        <h2>{`${name ? `${name} ` : ''}Clients`}</h2>
      </div>
      <Table
        teamId={teamId}
        showTeamSelect={isGlobalUser}
        noActions
        loading={loading}
        list={clients}
        onGetClients={isGlobalUser ? onGetClinicClients : onGetTeamClients}
        clinicId={clinicId}
        setClient={setClient}
      />
    </div>
  );
};

export default MyClients;
