import AddIcon from '@material-ui/icons/Add';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Table from '../../reusable/Table';
import ModalForm from './ModalForm';

import { useStoreActions, useStoreState } from '~/store/hooks';
import api from '~/services/api';
import useRole from '~/store/user/hooks/useRole';

import { IClient } from '~/services/api/client/types';
import { IDictionaryTypes } from '~/services/api/dictionaries/types';
import { ADD_CLIENT } from '~/ui/constants/paths';
import { extractErrorMessage } from '~/utils/error/error';

import styles from './ClientsManagement.module.scss';

const ClientsManagements = (): ReactElement => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<IClient>(null);
  const [dictionaries, setDictionaries] = useState<{ id: number; value: string }[]>([]);

  const clinicId = useStoreState(state => state.clinic.current?.id);
  const { clinic } = useStoreState(state => state.user.current);

  const { list: clients } = useStoreState(state => state.client);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetClinicClients = useStoreActions(actions => actions.client.onGetClinicClients);
  const { onGetActTeamsAvailable } = useStoreActions(action => action.actTeam);
  const onRestoreClient = useStoreActions(actions => actions.client.onRestoreClient);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const { isGlobalUser } = useRole();

  // fetch available teams for multiselect and dictionaries on mount
  const onMount = useCallback(async () => {
    setLoading(true);
    try {
      if (!clinicId) {
        await onGetMyClinic();
      }

      if (clinicId) {
        await onGetActTeamsAvailable({ clinicId: String(clinicId) });
      }

      if (!dictionaries?.length) {
        const { data } = await api.dictionaries.getAvailableTypeList(
          IDictionaryTypes.DischargeReason,
        );
        setDictionaries(data);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [clinicId, dictionaries?.length, onGetActTeamsAvailable, onGetMyClinic, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const options = dictionaries?.map(({ id, value }) => ({ label: value, value: id }));
  const description = `It appears you are restoring ${client?.firstName} ${client?.lastName}. Previous Discharge Date and Reason will be removed.`;
  const onConfirmRestore = async () => {
    try {
      await onRestoreClient({
        clinicId: String(clinicId),
        teamId: String(client?.actTeam.id),
        clientId: String(client.id),
      });
      showNotify({ message: 'Client successfully restored' });
      setClient(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Clients</h2>
        {!isGlobalUser && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate(ADD_CLIENT)}
          >
            Add Client
          </Button>
        )}
      </div>
      <Table
        showActions={!isGlobalUser}
        showTeamSelect
        noActions={isGlobalUser}
        list={clients}
        onGetClients={onGetClinicClients}
        clinicId={clinicId || clinic?.id}
        setClient={setClient}
        loading={loading}
      />
      {client && !client.isArchived && (
        <ModalForm client={client} clinicId={clinicId} setClient={setClient} options={options} />
      )}
      {client && client.isArchived && (
        <ConfirmModal
          confirmText="Restore"
          onConfirm={onConfirmRestore}
          description={description}
          onClose={() => setClient(null)}
        />
      )}
    </div>
  );
};

export default ClientsManagements;
