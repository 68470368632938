import onGetLocalAdmins from './onGetLocalAdmins';
import onDeactivateLocalAdmin from './onDeactivateLocalAdmin';
import onActivateLocalAdmin from './onActivateLocalAdmin';
import onGetLocalAdmin from './onGetLocalAdmin';
import onUnlockLocalAdmin from './onUnlockLocalAdmin';
import onAssignLocalAdmin from './onAssignLocalAdmin';
import onUnAssignLocalAdmin from './onUnAssignLocalAdmin';
import onArchiveLocalAdmin from './onArchiveLocalAdmin';

export default {
  onGetLocalAdmins,
  onDeactivateLocalAdmin,
  onActivateLocalAdmin,
  onGetLocalAdmin,
  onUnlockLocalAdmin,
  onAssignLocalAdmin,
  onUnAssignLocalAdmin,
  onArchiveLocalAdmin,
};
