import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateGlobalUser } from '../types';

const onCreateGlobalUser: IThunkCreateGlobalUser = thunk(async (_, payload) => {
  const { clinicId, data } = payload;

  await api.actTeamMember.createGlobalUser(clinicId, data);
});

export default onCreateGlobalUser;
