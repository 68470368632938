import { ReactElement } from 'react';

import Grid from '@material-ui/core/Grid';

import ClinicFeaturesList from './ClinicFeaturesList';

import formatPhoneNumber from '~/utils/formatPhoneNumber';
import { ICurrentClinic } from '~/services/api/clinic/types';
import styles from './ClinicProfile.module.scss';

interface IProps {
  clinic: ICurrentClinic;
}

const ClinicProfile = ({ clinic }: IProps): ReactElement => (
  <div className={styles.container}>
    <Grid container spacing={3}>
      <Grid item sm={6}>
        <div className={styles.itemsWrapper}>
          <div className={styles.item}>
            <h3 className={styles.header}>Clinic Details</h3>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Clinic Name</span>
            <span className={styles.itemDetails}>{clinic.name}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>City</span>
            <span className={styles.itemDetails}>{clinic.address.city}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Address 1</span>
            <span className={styles.itemDetails}>{clinic.address.line1}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Address 2</span>
            <span className={styles.itemDetails}>{clinic.address.line2}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Zip Code</span>
            <span className={styles.itemDetails}>{clinic.address.zipCode}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>State</span>
            <span className={styles.itemDetails}>{clinic.address.state.name}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Phone</span>
            <span className={styles.itemDetails}>{formatPhoneNumber(clinic.phone)}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>URL</span>
            <span className={styles.itemDetails}>{clinic.url}</span>
          </div>
        </div>
        <div className={styles.itemsWrapper}>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Team Census</span>
            <span className={styles.itemDetails}>{clinic.actCensus}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>EHR</span>
            <span className={styles.itemDetails}>{clinic.ehr.name}</span>
          </div>
        </div>
      </Grid>
      <Grid item sm={6}>
        <div className={styles.itemsWrapper}>
          <div className={styles.item}>
            <h3 className={styles.header}>Program Supervisor</h3>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>First Name</span>
            <span className={styles.itemDetails}>{clinic.programSupervisor.firstName}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Last Name</span>
            <span className={styles.itemDetails}>{clinic.programSupervisor.lastName}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Phone</span>
            <span className={styles.itemDetails}>{clinic.programSupervisor.phone}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.itemHeader}>Email</span>
            <span className={styles.itemDetails}>{clinic.programSupervisor.email}</span>
          </div>
        </div>

        <ClinicFeaturesList features={clinic.features} />
      </Grid>
    </Grid>
  </div>
);

export default ClinicProfile;
