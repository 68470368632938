import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkUnlockGlobalUser } from '../types';

const onUnlockGlobalUser: IThunkUnlockGlobalUser = thunk(async (_, payload) => {
  const { clinicId, teamMemberId } = payload;

  await api.actTeamMember.unlockGlobalUser(clinicId, teamMemberId);
});

export default onUnlockGlobalUser;
