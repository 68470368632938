import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkResendInvitationGlobalUser } from '../types';

const onResendInvitationGlobalUser: IThunkResendInvitationGlobalUser = thunk(async (_, payload) => {
  const { clinicId, teamMemberId } = payload;

  await api.actTeamMember.resendInvitationGlobalUser(clinicId, teamMemberId);
});

export default onResendInvitationGlobalUser;
