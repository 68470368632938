import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import useFeature from '~/store/clinic/hooks/useFeature';
import useRole from '~/store/user/hooks/useRole';
import { extractErrorMessage } from '~/utils/error/error';
import HealthDetails from '../../reusable/HealthDetails';
import PersonalDetails from '../../reusable/PersonalDetails';
import SupportContacts from '../../reusable/SupportContacts';
import TreatmentPlan from '../../reusable/TreatmentPlan';

import { IClinic } from '~/services/api/clinic/types';
import { CLIENT, EDIT_CLIENT, MY_CLIENTS } from '~/ui/constants/paths';

import EditIcon from '~/ui/assets/images/editWhiteSecond.svg';
import DocumentsBlock from '../../reusable/HealthDetails/DocumentsBlock';
import styles from './ClientProfile.module.scss';

type ILocationState = { pathname: string; state: { actTeamId: string; selectedTab: number } };

const ClientProfile = (): ReactElement => {
  const navigate = useNavigate();
  const { clientId, actTeamId: teamId } = useParams<{ clientId: string; actTeamId: string }>();
  const location: ILocationState = useLocation();

  const [restore, setShowRestore] = useState(false);
  const [tab, setTab] = useState(location?.state?.selectedTab || 0);
  const [loading, setLoading] = useState(false);

  const client = useStoreState(state => state.client.current);

  const { isActTeam, team, isGlobalUser } = useRole();
  const { isTreatmentPlansAvailable, isDocumentsAvailable } = useFeature();

  const actTeamId = location.state?.actTeamId || teamId || team?.id?.toString();

  const clinic = useStoreState(state => state.clinic.current || ({} as IClinic));

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);
  const onGetClient = useStoreActions(actions => actions.client.onGetClient);
  const { setDefaultClient, onRestoreClient } = useStoreActions(actions => actions.client);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      if (!clinic.id) {
        await onGetMyClinic();
      }

      if (clinic.id) {
        await onGetClient({
          clientId,
          teamId: actTeamId,
          clinicId: String(clinic.id),
        });
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [actTeamId, clientId, clinic.id, onGetClient, onGetMyClinic, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (!client?.isArchived) {
      setDefaultClient(client);
    }
  }, [client, setDefaultClient]);

  if (loading || !client || !clinic) return <Loader />;

  const itemsForBreadcrumbs = [
    isActTeam
      ? { to: MY_CLIENTS, title: `Clients` }
      : { to: CLIENT, title: `${clinic.name} Clients` },
    { to: location.pathname, title: 'Client Profile' },
  ];

  const restoreClient = async () => {
    try {
      await onRestoreClient({
        clientId,
        clinicId: String(clinic.id),
        teamId: String(client.actTeam.id),
      });

      await onGetClient({
        clientId,
        teamId: actTeamId,
        clinicId: String(clinic.id),
      });
      setShowRestore(false);
      showNotify({ message: 'Client successfully restored' });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const pushToEditClient = () =>
    navigate(
      EDIT_CLIENT.replace(':clientId', clientId).replace(':actTeamId', String(client.actTeam.id)),
      {
        state: { actTeamId: String(client.actTeam.id) },
      },
    );

  const startIcon = !client.isArchived && <img src={EditIcon} alt="edit" />;
  const buttonText = client.isArchived ? 'Restore Client' : 'Edit Personal Details';
  const method = client.isArchived ? () => setShowRestore(true) : pushToEditClient;
  const hideButton = tab !== 0 || isActTeam || isGlobalUser;
  const description = `It appears you are restoring ${client?.firstName} ${client?.lastName}. Previous Discharge Date and Reason will be removed. ?`;

  return (
    <div>
      <div className={styles.header}>
        <div>
          <h2>
            {client.firstName} {client.lastName}
          </h2>
          <Breadcrumbs itemsForBreadcrumbs={itemsForBreadcrumbs} />
        </div>
        <div className={styles.headerRightSide}>
          <div className={classNames({ [styles.invisible]: hideButton })}>
            <Button
              color="primary"
              variant="contained"
              startIcon={startIcon}
              onClick={method}
              disabled={hideButton}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
      <Tabs
        value={tab}
        textColor="primary"
        indicatorColor="primary"
        onChange={(_e, val) => setTab(val)}
        className={styles.tabsWrapper}
      >
        <Tab label="PERSONAL DETAILS" />
        <Tab label="HEALTH DETAILS" />
        <Tab label="COLLATERAL CONTACTS" />
        {isTreatmentPlansAvailable && <Tab label="TREATMENT PLANS" />}
        {isDocumentsAvailable && <Tab label="DOCUMENTS" />}
      </Tabs>
      {tab === 0 && (
        <PersonalDetails client={client} clinicId={String(clinic.id)} actTeamId={actTeamId} />
      )}
      {tab === 1 && (
        <HealthDetails
          client={client}
          clinicId={String(clinic.id)}
          actTeamId={actTeamId}
          noActions={isActTeam || isGlobalUser}
        />
      )}
      {tab === 2 && (
        <SupportContacts
          client={client}
          clinicId={String(clinic.id)}
          actTeamId={actTeamId}
          noActions={client.isArchived}
        />
      )}
      {tab === 3 && isTreatmentPlansAvailable && (
        <TreatmentPlan client={client} clinicId={String(clinic.id)} actTeamId={actTeamId} />
      )}
      {tab === 4 && isDocumentsAvailable && <DocumentsBlock />}
      {restore && (
        <ConfirmModal
          confirmText="Restore"
          onConfirm={restoreClient}
          onClose={() => setShowRestore(false)}
          description={description}
        />
      )}
    </div>
  );
};

export default ClientProfile;
