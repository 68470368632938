import setActTeams from './setActTeams';
import setActTeam from './setActTeam';
import resetPagination from './resetPagination';
import setAvailableActTeams from './setAvailableActTeams';
import resetActTeamStore from './resetActTeamStore';

export default {
  setActTeams,
  setActTeam,
  resetPagination,
  setAvailableActTeams,
  resetActTeamStore,
};
