import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkOnSendAiRequest } from '../types';

const onSendAiRequest: IThunkOnSendAiRequest = thunk(async ({ setAiTestResponse }, payload) => {
  const data = await api.ai.sendAiRequest(payload).then(r => r.data);

  setAiTestResponse(data.results);
});

export default onSendAiRequest;
