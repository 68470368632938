import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientAllocationActions, IThunkGetAllocationClientsByGroupGU } from '../types';

const onGetAllocationClientsByGroupGU: IThunkGetAllocationClientsByGroupGU = thunk(
  async ({ setAllocationClientsByGroup }: Actions<IClientAllocationActions>, payload) => {
    const allocationClientsByGroup = await api.clientAllocation
      .getAllocationClientsByGroupGU(payload)
      .then(r => r.data);

    setAllocationClientsByGroup(allocationClientsByGroup);
  },
);

export default onGetAllocationClientsByGroupGU;
