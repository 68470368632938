import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import capitalize from 'lodash/capitalize';
import curry from 'lodash/curry';
import get from 'lodash/get';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import ConfirmModal from '~/ui/components/common/ConfirmModal';
import Loader from '~/ui/components/common/Loader';
import Modal from '~/ui/components/common/Modal';
import DetailsBlock from './DetailsBlock';
import Forms from './Forms';
import RisksBlock from './RisksBlock';

import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';
import replaceAt from '~/utils/text/replaceAt';
import { formatHealthDetailsItem, groupByStatus, hasSeverAllergy } from './utils';

import { IClientDetailed } from '~/services/api/client/types';
import { IRequestParams } from '~/services/api/clientDetails/types';
import { generalDate } from '~/ui/constants/dateFormat';
import { IDetails, IDetailsInfo, ITypeDetails } from './types';

import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import loaderStyles from '~/ui/components/common/Loader/Loader.module.scss';
import styles from './HealthDetails.module.scss';

interface IProps {
  client: IClientDetailed;
  clinicId?: string;
  actTeamId?: string;
  noActions?: boolean;
}

const HealthDetails = ({ client, clinicId, actTeamId, noActions }: IProps): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [formType, setFormType] = useState<ITypeDetails | null>(null);
  const [details, setDetails] = useState<IDetails | null>(null);
  const [detailsInfo, setDetailsInfo] = useState<IDetailsInfo | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { deEscalationTechniques, diagnoses, medications, triggers, allergies, current } =
    useStoreState(state => state.clientDetails);
  const { primaryDiagnosis } = useStoreState(state => state.client.current);

  const { showNotify, showError } = useStoreActions(actions => actions.snackbar);

  const {
    onGetDeEscalationTechniques,
    onGetDiagnoses,
    onGetMedications,
    onGetMedication,
    onGetTrigger,
    onGetTriggers,
    onGetAllergy,
    onGetAllergies,
    onArchiveTrigger,
    onRestoreTrigger,
    onArchiveMedication,
    onRestoreMedication,
    onArchiveAllergy,
    onRestoreAllergy,
    onGetDeEscalation,
    onArchiveDeEscalation,
    onRestoreDeEscalation,
    onArchiveSecondaryDiagnosis,
    onRestoreSecondaryDiagnosis,
    onGetSecondaryDiagnosis,
    setCurrent,
  } = useStoreActions(actions => actions.clientDetails);

  const methods = {
    trigger: {
      restore: onRestoreTrigger,
      archive: onArchiveTrigger,
    },
    medication: {
      archive: onArchiveMedication,
      restore: onRestoreMedication,
    },
    allergy: {
      archive: onArchiveAllergy,
      restore: onRestoreAllergy,
    },
    deEscalation: {
      archive: onArchiveDeEscalation,
      restore: onRestoreDeEscalation,
    },
    secondaryDiagnosis: {
      archive: onArchiveSecondaryDiagnosis,
      restore: onRestoreSecondaryDiagnosis,
    },
  };

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      const payload = { clinicId, teamId: actTeamId, clientId: String(client.id) };

      await Promise.all([
        onGetDeEscalationTechniques(payload),
        onGetAllergies(payload),
        onGetDiagnoses(payload),
        onGetMedications(payload),
        onGetTriggers(payload),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    actTeamId,
    client.id,
    clinicId,
    onGetAllergies,
    onGetDeEscalationTechniques,
    onGetDiagnoses,
    onGetMedications,
    onGetTriggers,
    showError,
  ]);

  const loadDetails = useCallback(async () => {
    try {
      setLoadingDetails(true);
      const payload = {
        clinicId,
        clientId: String(client.id),
        teamId: actTeamId,
        id: String(details?.id),
      };

      if (details && details.type === 'trigger') {
        await onGetTrigger(payload);
      }

      if (details && details.type === 'allergy') {
        await onGetAllergy(payload);
      }

      if (details && details.type === 'medication') {
        await onGetMedication(payload);
      }

      if (details && details.type === 'deEscalation') {
        await onGetDeEscalation(payload);
      }

      if (details && details.type === 'secondaryDiagnosis') {
        await onGetSecondaryDiagnosis(details.id);
      }

      setLoadingDetails(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    client.id,
    clinicId,
    details,
    onGetAllergy,
    onGetDeEscalation,
    onGetMedication,
    onGetSecondaryDiagnosis,
    onGetTrigger,
    showError,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    loadDetails();
  }, [loadDetails]);

  const onAdd = (title: string, type: ITypeDetails) => {
    setModalTitle(title);
    setFormType(type);
  };

  const onEdit = curry(
    (
      type: ITypeDetails,
      healthDetailsType: string,
      removeHealthDetailsName: boolean,
      id: number,
      healthDetailsName: string,
    ) => {
      let title = `Edit “${healthDetailsName}” ${healthDetailsType}`;

      if (removeHealthDetailsName) {
        title = title.replace(/“[\w\s]*”/gi, '');
      }

      setModalTitle(title);
      setDetails({ type, id });
      setFormType(type);
    },
  );

  const requestInfo = { clientId: String(client.id), clinicId, teamId: actTeamId };

  const confirmText = detailsInfo?.method === 'archive' ? 'Archive' : 'Restore';
  const description = `Are you sure you want to ${confirmText.toLowerCase()} “${
    detailsInfo?.name
  }” ${detailsInfo?.title || detailsInfo?.typeDetails}?`;

  const initialEndDate = detailsInfo?.endDate
    ? new Date(replaceAt(detailsInfo.endDate, 11, '12'))
    : null;

  const archiveMedicationCustomContent = (
    <div className={styles.selectContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormControl variant="outlined" className={styles.dateInput} fullWidth>
          <InputLabel shrink>End Date</InputLabel>
          <DatePicker
            InputProps={{
              endAdornment: <CalendarIcon color={variables.colorRed} />,
            }}
            inputVariant="outlined"
            fullWidth
            format={generalDate}
            size="small"
            value={endDate || initialEndDate}
            onChange={setEndDate}
          />
        </FormControl>
      </MuiPickersUtilsProvider>
    </div>
  );

  const method = get(methods, [detailsInfo?.typeDetails, detailsInfo?.method], () => {});

  const onConfirmModal = async (payload: Required<IRequestParams & { endDate?: string }>) => {
    try {
      const type = detailsInfo?.method === 'archive' ? 'archived' : 'restored';

      await method(payload);

      showNotify({
        message: `${capitalize(
          detailsInfo?.title || detailsInfo?.typeDetails,
        )} successfully ${type}`,
      });

      setDetailsInfo(null);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const showSecondSubmit = !Object.keys(details || {}).length;

  const primaryDiagnoses = {
    active: client.primaryDiagnosis ? [formatHealthDetailsItem(client.primaryDiagnosis)] : [],
    archived: client.pastPrimaryDiagnoses || [],
  };

  const isClientActive = !client.isArchived;

  return (
    <div className={styles.wrapper}>
      {loading && <Loader className={loaderStyles.loaderContainer} />}
      <div className={styles.width}>
        <DetailsBlock
          onAdd={() => onAdd('Add Primary Diagnosis', 'primaryDiagnosis')}
          onEdit={onEdit('primaryDiagnosis', 'Primary Diagnosis', true)}
          buttonText={
            !client.primaryDiagnosis?.diagnosis && isClientActive && !noActions
              ? 'Add Primary Diagnosis'
              : ''
          }
          noActions={noActions}
          title="Primary Diagnosis"
          showRestore={false}
          showArchive={false}
          showEdit={isClientActive}
          healthDetailGroup={primaryDiagnoses}
          labelGrey
        />
        <DetailsBlock
          onAdd={() => onAdd('Add Secondary Diagnosis', 'secondaryDiagnosis')}
          onEdit={onEdit('secondaryDiagnosis', 'Secondary Diagnosis', true)}
          setDetailsInfo={rest =>
            setDetailsInfo({
              title: 'secondary diagnosis',
              typeDetails: 'secondaryDiagnosis',
              ...rest,
            })
          }
          noActions={noActions}
          showEdit={isClientActive}
          showArchive={isClientActive}
          showRestore={isClientActive}
          title="Secondary Diagnosis"
          buttonText={isClientActive && !noActions && 'Add Secondary Diagnosis'}
          healthDetailGroup={groupByStatus(diagnoses)}
        />
        <DetailsBlock
          onAdd={() => onAdd('Add Trigger', 'trigger')}
          onEdit={onEdit('trigger', 'trigger', false)}
          setDetailsInfo={rest => setDetailsInfo({ typeDetails: 'trigger', ...rest })}
          title="Triggers"
          buttonText={isClientActive && 'Add Trigger'}
          showEdit={isClientActive}
          showArchive={isClientActive}
          showRestore={isClientActive}
          healthDetailGroup={groupByStatus(triggers)}
        />
        <RisksBlock />
      </div>
      <div className={styles.width}>
        <DetailsBlock
          onAdd={() => onAdd('Add Medication', 'medication')}
          onEdit={onEdit('medication', 'Medication', true)}
          title="Medications"
          setDetailsInfo={rest => setDetailsInfo({ typeDetails: 'medication', ...rest })}
          buttonText={isClientActive && 'Add Medication'}
          showEdit={isClientActive}
          showArchive={isClientActive}
          showRestore={isClientActive}
          healthDetailGroup={groupByStatus(medications)}
          moreLabelWidth
        />
        <DetailsBlock
          onAdd={() => onAdd('Add Allergy', 'allergy')}
          onEdit={onEdit('allergy', 'Allergy', true)}
          setDetailsInfo={rest =>
            setDetailsInfo({
              title: 'allergy',
              typeDetails: 'allergy',
              ...rest,
            })
          }
          showEdit={isClientActive}
          showArchive={isClientActive}
          showRestore={isClientActive}
          title="Allergies"
          buttonText={isClientActive && 'Add Allergy'}
          healthDetailGroup={groupByStatus(allergies)}
          moreLabelWidth
          blockClassName={hasSeverAllergy(allergies) ? styles.severeAllergy : undefined}
        />
        <DetailsBlock
          onAdd={() => onAdd('Add De-escalation Technique', 'deEscalation')}
          onEdit={onEdit('deEscalation', 'De-escalation Technique', true)}
          setDetailsInfo={rest =>
            setDetailsInfo({
              title: 'de-escalation technique',
              typeDetails: 'deEscalation',
              ...rest,
            })
          }
          showEdit={isClientActive}
          showArchive={isClientActive}
          showRestore={isClientActive}
          title="De-escalation Techniques"
          buttonText={isClientActive && 'Add De-escalation Technique'}
          healthDetailGroup={groupByStatus(deEscalationTechniques)}
        />
        {(modalTitle || (details && details.id)) && (
          <Modal
            className={styles.modal}
            open
            onClose={() => {
              setModalTitle(null);
              setDetails(null);
              setCurrent(null);
            }}
          >
            {loadingDetails ? (
              <Loader />
            ) : (
              <div className={styles.modalWrapper}>
                <h3 className={styles.modalTitle}>{modalTitle}</h3>
                <Forms
                  formType={formType}
                  setModalTitle={setModalTitle}
                  setDetails={setDetails}
                  requestInfo={requestInfo}
                  details={details}
                  showSecondSubmit={showSecondSubmit}
                  current={current}
                  primaryDiagnosis={primaryDiagnosis}
                />
              </div>
            )}
          </Modal>
        )}
        {detailsInfo && (
          <ConfirmModal
            onConfirm={() => {
              onConfirmModal({
                clientId: String(client.id),
                clinicId,
                teamId: actTeamId,
                id: String(detailsInfo?.id),
                endDate: endDate?.toISOString() || initialEndDate?.toISOString(),
              });
            }}
            onClose={() => {
              setEndDate(null);
              setDetailsInfo(null);
            }}
            description={description}
            confirmText={confirmText}
            customContent={
              detailsInfo.method === 'archive' && detailsInfo.typeDetails === 'medication'
                ? archiveMedicationCustomContent
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default HealthDetails;
