import onGetActTeamMembers from './onGetActTeamMembers';
import onGetActTeamMember from './onGetActTeamMember';
import onUnlockTeamMember from './onUnlockTeamMember';
import onActivateTeamMember from './onActivateTeamMember';
import onAssignTeamMember from './onAssignTeamMember';
import onUnAssignTeamMember from './onUnAssignTeamMember';
import onDeactivateTeamMember from './onDeactivateTeamMember';
import onGetAvailableActTeamMembers from './onGetAvailableActTeamMembers';
import onGetAvailableTeamMembers from './onGetAvailableTeamMembers';
import onArchiveTeamMember from './onArchiveTeamMember';
// global user
import onCreateGlobalUser from './onCreateGlobalUser';
import onUnlockGlobalUser from './onUnlockGlobalUser';
import onActivateGlobalUser from './onActivateGlobalUser';
import onArchiveGlobalUser from './onArchiveGlobalUser';
import onDeactivateGlobalUser from './onDeactivateGlobalUser';
import onResendInvitationGlobalUser from './onResendInvitationGlobalUser';

export default {
  onGetActTeamMembers,
  onGetActTeamMember,
  onUnlockTeamMember,
  onAssignTeamMember,
  onUnAssignTeamMember,
  onActivateTeamMember,
  onDeactivateTeamMember,
  onGetAvailableActTeamMembers,
  onGetAvailableTeamMembers,
  onArchiveTeamMember,
  // global user
  onCreateGlobalUser,
  onUnlockGlobalUser,
  onActivateGlobalUser,
  onArchiveGlobalUser,
  onDeactivateGlobalUser,
  onResendInvitationGlobalUser,
};
