import { ReactElement, useEffect, useRef } from 'react';
import { components, GroupTypeBase, OptionProps } from 'react-select';
import classNames from 'classnames';

import Chip from '~/ui/components/common/Chip';
import CustomCheckbox from '../../CustomCheckbox';

import useIsVisible from '~/ui/pages/Reports/hooks/useIsVisible';
import extractChipText from '~/utils/text/extractChipText';

import { IOption } from '~/types';

import styles from '../../Select/Select.module.scss';
import chipStyles from '~/ui/components/common/Chip/Chip.module.scss';

const INDEX_TO_TRIGGER_FROM_END = 5;

const Option = ({
  label,
  isSelected,
  loadMore,
  ...rest
}: OptionProps<IOption, boolean, GroupTypeBase<IOption>> & {
  loadMore?: () => void;
}): ReactElement => {
  const { text, chipText } = extractChipText(label);

  const lastOptionRef = useRef<HTMLDivElement>();
  const currentIndex = rest.options?.findIndex(v => v.value === rest.data.value);
  const listLength = rest.options?.length;
  const isPreLastItem = listLength - INDEX_TO_TRIGGER_FROM_END === (currentIndex || 0) + 1; // trigger loadMore in advance before reaching end of the list

  const isVisible = useIsVisible(lastOptionRef);

  useEffect(() => {
    if (isVisible && isPreLastItem && loadMore) {
      loadMore();
      lastOptionRef.current = null;
    }
  }, [isVisible, isPreLastItem, loadMore]);

  return (
    <components.Option
      {...rest}
      isSelected={isSelected}
      label={text}
      className={classNames(styles.menuOption, { [styles.disabled]: rest.isDisabled })}
      innerRef={isPreLastItem ? lastOptionRef : rest.innerRef} // attach ref to the pre latest item for infinity scroll functionality
    >
      <CustomCheckbox size="small" checked={isSelected} disabled={rest.isDisabled} />
      <span>{text}</span>
      {!!chipText && (
        <Chip text={chipText} className={classNames(chipStyles.outlineLabel, styles.chip)} />
      )}
    </components.Option>
  );
};

export default Option;
