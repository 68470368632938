import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IClientAllocationActions, IThunkGetClientAllocationGU } from '../types';

const onGetClientAllocation: IThunkGetClientAllocationGU = thunk(
  async ({ setClientAllocation }: Actions<IClientAllocationActions>, payload) => {
    const clientAllocations = await api.clientAllocation
      .getClientAllocationGU(payload)
      .then(r => r.data);

    setClientAllocation(clientAllocations);

    return clientAllocations;
  },
);

export default onGetClientAllocation;
